<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">Хэрэглэгч бүртгэх</h2>
    </div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-10 sm:py-10 mt-5">
      <div
        class="px-5 sm:px-20 pt-10 border-t border-gray-200 dark:border-dark-5"
      >
        <div class="font-medium text-base">Шаардлагатай мэдээлэл</div>
      <form @submit.prevent="submit ">
        <div class="grid grid-cols-12 gap-4 row-gap-5 mt-5">

          <div class="intro-y col-span-12 sm:col-span-6">
            <div class="mb-2">Нэр</div>
            <el-input
              type="text"
              placeholder="..."
              v-model="info.name"
              required
              clearable
            />
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <div class="mb-2">Цахим хаяг</div>
            <el-input
              type="email"
              placeholder="e.g: example@gmail.com"
              required
              clearable
              v-model="info.email"
            />
          </div>

          <div class="intro-y col-span-12 sm:col-span-6">
            <div class="mb-2">Утасны дугаар</div>
            <el-input
              type="text"
              placeholder="e.g: 0000 0000"
              pattern="[0-9]{8}"
              required
              v-model="info.phone"
              clearable
            >
            </el-input>
          </div>
          
          <div class="intro-y col-span-12 sm:col-span-6">
            <div class="mb-2">Зөвшөөрөл</div>
            <el-select v-model="info.permission" required class="w-full" placeholder="e.g: Admin">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="intro-y col-span-12 sm:col-span-12">
            <div class="mb-2">Хаяг</div>
            <textarea
              type="text"
              rows="3"
              class="input w-full border flex-1"
              required
              placeholder="e.g: Ulaanbaatar, Khan-uul ..."
              v-model="info.address"
            >
            </textarea>
          </div>
          
          <div
            class="intro-y col-span-12 flex items-center justify-center sm:justify-end"
          >
            <button
              class="button w-24 justify-center block bg-theme-1 text-white ml-2"
              type="submit"
              :disabled="isDisabled"
            >
              Хадгалах
            </button>
          </div>
        </div>
      </form>
      </div>
    </div>
    <!-- END: Wizard Layout -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {
        name: '',
        email: '',
        phone: '',
        address: '',
        permission: 'Admin'
      },
      isDisabled: false,
      options: [{
          value: 'Admin',
          label: 'Admin'
        }, {
          value: 'Operator',
          label: 'Operator'
        }]
    }
  },  
  mounted() {

  },
  methods: {
    submit() {
      let rts = this;
      const token = localStorage.getItem('token');
      this.$axios({
        method: 'POST',
        url: this.$appUrl+'/api/admin/add-admin', 
        headers: {
              'Authorization': `Bearer ${token}`
            },
        data: {
          name: rts.info.name,
          email: rts.info.email,
          phone: rts.info.phone,
          address: rts.info.address,
          permission: rts.info.permission
        }
        }).then(function(data) {
          if(data.data.result == 'success') {
            rts.$message({
              message: 'Амжилттай бүртгэгдлээ.',
              type: 'success'
            });
            rts.$router.push('/admins');
          } else if(data.data.result == 'fail') {
            rts.$message({
              message: 'Хэрэглэгч бүртгэлтэй байна.',
              type: 'error'
            });
          }
        });
    }
  }
}
</script>