<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Chat</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button class="button text-white bg-theme-1 shadow-md mr-2">
          Start New Chat
        </button>
        
        <div class="dropdown ml-auto sm:ml-0">
          <button
            class="dropdown-toggle button px-2 box text-gray-700 dark:text-gray-300"
          >
            <span class="w-5 h-5 flex items-center justify-center">
              <PlusIcon class="w-4 h-4" />
            </span>
          </button>
          <div class="dropdown-box w-40">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2">
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <UsersIcon class="w-4 h-4 mr-2" /> Create Group
              </a>
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <SettingsIcon class="w-4 h-4 mr-2" /> Settings
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="intro-y chat grid grid-cols-12 gap-5 mt-5">
      <!-- BEGIN: Chat Side Menu -->
      <div class="col-span-12 lg:col-span-4 xxl:col-span-3">
        <div class="intro-y pr-1">
          <div class="box p-2">
            <div class="chat__tabs nav-tabs justify-center flex">
              <a
                data-toggle="tab"
                data-target="#chats"
                href="javascript:;"
                class="flex-1 py-2 rounded-md text-center active"
                >Онлайн</a
              >
              <a
                data-toggle="tab"
                data-target="#friends"
                href="javascript:;"
                class="flex-1 py-2 rounded-md text-center"
                >Бүгд</a
              >
              <a
                data-toggle="tab"
                data-target="#profile"
                href="javascript:;"
                class="flex-1 py-2 rounded-md text-center"
                >Profile</a
              >
            </div>
          </div>
        </div>
        <div class="tab-content">
          <div id="chats" class="tab-content__pane active">
            <div class="pr-1">
              <div class="box px-5 pt-5 pb-5 lg:pb-0 mt-5">
                <div class="relative text-gray-700 dark:text-gray-300 pb-5">
                  <input
                    type="text"
                    class="input input--lg w-full bg-gray-200 pr-10 placeholder-theme-13"
                    placeholder="Хэрэглэгчийн нэрээр хайх..."
                  />
                  <SearchIcon
                    class="w-4 h-4 hidden sm:absolute my-auto inset-y-0 mr-3 right-0"
                  />
                </div>
              </div>
            </div>
            <div
              class="chat__chat-list overflow-y-auto scrollbar-hidden pr-1 pt-1 mt-4"
            >
              <div v-for="(chats, index) in chat.rooms"
                  :key="index">
                <div v-if="chats.user.Online === true"
                  class="intro-x cursor-pointer box relative flex items-center p-5"
                  :class="{ 'mt-5': index }"
                  @click="showChatBox(chats.name)"
                >
                  <div class="w-12 h-12 flex-none image-fit mr-1">
                    <img
                      alt="user"
                      class="rounded-full"
                      :src="`${$appUrl}/images/users/${chats.user.avatar}`"
                    />
                    <div
                      class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"
                    ></div>
                  </div>
                  <div class="ml-2 overflow-hidden">
                    <div class="flex items-center">
                      <a href="javascript:;" class="font-medium">{{
                        chats.user.name
                      }}</a>
                      
                    </div>
                    <div v-for="(sms, count) in chat.allMessage"
                    :key="count">
                      <div v-if="chats.name == sms.chatroom">
                        <div class="w-full truncate text-gray-600">
                          {{ sms.message }}
                        </div>

                        <div
                          v-if="sms.seen === false && sms.operator === null"
                          class="w-5 h-5 flex items-center justify-center absolute top-0 right-0 text-xs text-white rounded-full bg-theme-1 font-medium -mt-1 -mr-1"
                        >
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="friends" class="tab-content__pane">
            <div class="pr-1">
              <div class="box p-5 mt-5">
                <div class="relative text-gray-700 dark:text-gray-300">
                  <input
                    type="text"
                    class="input input--lg w-full bg-gray-200 pr-10 placeholder-theme-13"
                    placeholder="Хэрэглэгчийн нэрээр хайх..."
                  />
                  <SearchIcon
                    class="w-4 h-4 hidden sm:absolute my-auto inset-y-0 mr-3 right-0"
                  />
                </div>
              </div>
            </div>
            <div
              class="chat__chat-list overflow-y-auto scrollbar-hidden pr-1 pt-1 mt-4"
            >
            <div
                v-for="(chats, index) in chat.rooms"
                :key="index"
                class="intro-x cursor-pointer box relative flex items-center p-5"
                :class="{ 'mt-5': index }"
                @click="showChatBox(chats.name)"
              >
                <div class="w-12 h-12 flex-none image-fit mr-1">
                  <img
                    alt="user"
                    class="rounded-full"
                    :src="`${$appUrl}/images/users/${chats.user.avatar}`"
                  />
                  <div v-if="chats.user.Online === true"
                    class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"
                  ></div>
                  <div v-else-if="chats.user.Online === false"
                    class="w-3 h-3 bg-theme-7 absolute right-0 bottom-0 rounded-full border-2 border-white"
                  ></div>
                </div>
                <div class="ml-2 overflow-hidden">
                  <div class="flex items-center">
                    <a href="javascript:;" class="font-medium">{{
                      chats.user.lastname 
                    }} {{chats.user.firstname}}</a>
                    
                  </div>
                  <div v-for="(sms, count) in chat.allMessage"
                  :key="count">
                    <div v-if="chats.name == sms.chatroom">
                      <div class="w-full truncate text-gray-600">
                        {{ sms.message }}
                      </div>

                      <div
                        v-if="sms.seen === false && sms.operator === null"
                        class="w-5 h-5 flex items-center justify-center absolute top-0 right-0 text-xs text-white rounded-full bg-theme-1 font-medium -mt-1 -mr-1"
                      >
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="profile" class="tab-content__pane">
            <div class="pr-1">
              <div class="box px-5 py-10 mt-5">
                <div
                  class="w-20 h-20 flex-none image-fit rounded-full overflow-hidden mx-auto"
                >
                  <img
                    alt="Midone Tailwind HTML Admin Template"
                    :src="file.src"
                  />
                </div>
                <div class="text-center mt-3">
                  <div class="font-medium text-lg">
                    {{ $f()[0]["users"][0]["name"] }}
                  </div>
                  <div class="text-gray-600 mt-1">
                    Tailwind HTML Admin Template
                  </div>
                </div>
              </div>
              <div class="box p-5 mt-5">
                <div class="flex items-center border-b dark:border-dark-5 pb-5">
                  <div class="">
                    <div class="text-gray-600">Country</div>
                    <div>New York City, USA</div>
                  </div>
                  <GlobeIcon class="w-4 h-4 text-gray-600 ml-auto" />
                </div>
                <div class="flex items-center border-b dark:border-dark-5 py-5">
                  <div class="">
                    <div class="text-gray-600">Phone</div>
                    <div>+32 19 23 62 24 34</div>
                  </div>
                  <MicIcon class="w-4 h-4 text-gray-600 ml-auto" />
                </div>
                <div class="flex items-center border-b dark:border-dark-5 py-5">
                  <div class="">
                    <div class="text-gray-600">Email</div>
                    <div>{{ $f()[0]["users"][0]["email"] }}</div>
                  </div>
                  <MailIcon class="w-4 h-4 text-gray-600 ml-auto" />
                </div>
                <div class="flex items-center pt-5">
                  <div class="">
                    <div class="text-gray-600">Joined Date</div>
                    <div>{{ $f()[0]["dates"][0] }}</div>
                  </div>
                  <ClockIcon class="w-4 h-4 text-gray-600 ml-auto" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Chat Side Menu -->
      <!-- BEGIN: Chat Content -->
      <div class="intro-y col-span-12 lg:col-span-8 xxl:col-span-9"  :style="{'overflow': 'hidden'}">
        <div class="chat__box box">
          <!-- BEGIN: Chat Active -->
          <div v-if="chatBox" class="h-full flex flex-col">
            <div
              class="flex flex-col sm:flex-row border-b border-gray-200 dark:border-dark-5 px-5 py-4"
            >
              <div class="flex items-center">
                <div
                  class="w-10 h-10 sm:w-12 sm:h-12 flex-none image-fit relative"
                >
                  <img
                    alt="Midone Tailwind HTML Admin Template"
                    class="rounded-full"
                    :src="client.image"
                  />
                </div>
                <div class="ml-3 mr-auto">
                  <div class="font-medium text-base">
                    {{ client.name }}
                  </div>
                  <div class="text-gray-600 text-xs sm:text-sm">
                    <span v-if="client.status === 'Online'" class="mx-1" :style="{'color': '#45d153', 'font-weight': '700', 'font-size': '15px'}">•</span> 
                    <span v-else class="mx-1" :style="{'font-weight': '700', 'font-size': '15px'}">•</span> 
                    {{ client.status }}
                  </div>
                </div>
              </div>
              <div
                class="flex items-center sm:ml-auto mt-5 sm:mt-0 border-t sm:border-0 border-gray-200 pt-3 sm:pt-0 -mx-5 sm:mx-0 px-5 sm:px-0"
              >
                <a href="javascript:;" class="w-5 h-5 text-gray-500">
                  <SearchIcon class="w-5 h-5" />
                </a>
                <a href="javascript:;" class="w-5 h-5 text-gray-500 ml-5">
                  <UserPlusIcon class="w-5 h-5" />
                </a>
                <div class="dropdown ml-auto sm:ml-3">
                  <a
                    href="javascript:;"
                    class="dropdown-toggle w-5 h-5 text-gray-500"
                  >
                    <MoreVerticalIcon class="w-5 h-5" />
                  </a>
                  <div class="dropdown-box w-40">
                    <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                      <a
                        href=""
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      >
                        <Share2Icon class="w-4 h-4 mr-2" />
                        Share Contact
                      </a>
                      <a
                        href=""
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      >
                        <SettingsIcon class="w-4 h-4 mr-2" />
                        Settings
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="overflow-y-scroll scrollbar-hidden px-5 pt-5">
              
              <div v-for="(msg, index) in chat.roomMessages"
              :key="index">
              
              <div v-if="msg.operator === null" class="chat__box__text-box flex items-end float-left mb-3">
                 <div
                  class="w-10 h-10 hidden sm:block flex-none image-fit relative mr-5"
                >
                  <img
                    alt="Midone Tailwind HTML Admin Template"
                    class="rounded-full"
                    :src="`${$appUrl}/images/users/${msg.user.avatar}`"
                  />
                </div>
                <div v-if="msg.file === false" class="bg-gray-200 dark:bg-dark-5 px-4 py-3 text-gray-700 dark:text-gray-300 rounded-r-md rounded-t-md">

                  {{msg.message}}

                  <div class="mt-1 text-xs text-gray-600">{{ dateFormat(msg.createdAt) }}</div>
                </div>

                <div v-else class="px-4 py-3 text-white">

                    <img v-if="msg.file_type == 'image'"
                      alt="chat message"
                      :src="`${$appUrl}/images/ops/finger.jpg`"
                      :style="{'width': '200px', 'height': 'auto'}"
                    />

                    <audio v-else-if="msg.file_type == 'audio'" controls>
                      <source :src="`${$appUrl}/audio/message/${msg.message}`" type="audio/wav">
                      <source :src="`${$appUrl}/audio/message/${msg.message}`" type="audio/wav">
                      Your browser does not support the audio element.
                    </audio>

                  <div class="mt-1 text-xs text-gray-600">{{ dateFormat(msg.createdAt) }}</div>
                </div>
                
                <div class="hidden sm:block dropdown ml-3 my-auto">
                  <a
                    href="javascript:;"
                    class="dropdown-toggle w-4 h-4 text-gray-500"
                  >
                    <MoreVerticalIcon class="w-4 h-4" />
                  </a>
                  <div class="dropdown-box w-40">
                    <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                      <a
                        href=""
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      >
                        <CornerUpLeftIcon class="w-4 h-4 mr-2" />
                        Reply
                      </a>
                      <a
                        href=""
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      >
                        <TrashIcon class="w-4 h-4 mr-2" /> Delete
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else-if="msg.operator != null" class="chat__box__text-box flex items-end float-right mb-3">
                <div class="hidden sm:block dropdown mr-3 my-auto">
                  <a
                    href="javascript:;"
                    class="dropdown-toggle w-4 h-4 text-gray-500"
                  >
                    <MoreVerticalIcon class="w-4 h-4" />
                  </a>
                  <div class="dropdown-box w-40">
                    <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                      <a
                        href=""
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      >
                        <CornerUpLeftIcon class="w-4 h-4 mr-2" />
                        Reply
                      </a>
                      <a
                        href=""
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      >
                        <TrashIcon class="w-4 h-4 mr-2" /> Delete
                      </a>
                    </div>
                  </div>
                </div>

                <div v-if="msg.file === false" class="bg-theme-1 px-4 py-3 text-white rounded-l-md rounded-t-md">

                  {{msg.message}}

                  <div class="mt-1 text-xs text-gray-600">{{ dateFormat(msg.createdAt) }}</div>
                </div>

                <div v-else class="px-4 py-3 text-white">
                    <img v-if="msg.file_type == 'image'"
                      alt="chat message"
                      :src="msg.message"
                      :style="{'width': '200px', 'height': 'auto'}"
                    />

                    <audio v-else-if="msg.file_type == 'audio'" controls>
                      <source :src="`${$appUrl}/audio/message/${msg.message}`" type="audio/wav">
                      <source :src="`${$appUrl}/audio/message/${msg.message}`" type="audio/wav">
                      Your browser does not support the audio element.
                    </audio>
                    
                  <div class="mt-1 text-xs text-gray-600">{{ dateFormat(msg.createdAt) }}</div>
                </div>


                <div
                  class="w-10 h-10 hidden sm:block flex-none image-fit relative ml-5"
                >
                  <img
                    alt="Midone Tailwind HTML Admin Template"
                    class="rounded-full"
                    :src="`${$appUrl}/images/ops/${msg.operator.avatar}`"
                  />
                </div>
              </div>
              
              <div class="clear-both"></div>
              </div>
            </div>
            <div
              class="pt-4 pb-10 sm:py-4 flex items-center border-t border-gray-200 dark:border-dark-5"
            >
              <textarea
                class="chat__box__input input dark:bg-dark-3 w-full h-16 resize-none border-transparent px-5 py-3 focus:shadow-none"
                rows="1"
                placeholder="Type your message..."
                @keyup.enter="sendMessasage"
                ref="MsgInputBox"
                v-model="chat.currentMessage"
              ></textarea>
              <div
                class="flex absolute sm:static left-0 bottom-0 ml-5 sm:ml-0 mb-5 sm:mb-0"
              >
                <div class="dropdown mr-3 sm:mr-5">
                  <a
                    href="javascript:;"
                    class="dropdown-toggle w-4 h-4 sm:w-5 sm:h-5 block text-gray-500"
                  >
                    <SmileIcon class="w-full h-full" />
                  </a>
                  <div class="chat-dropdown dropdown-box w-40 pb-2">
                    <div class="dropdown-box__content">
                      <div
                        class="chat-dropdown__box box dark:bg-dark-1 flex flex-col pb-3 -mt-2"
                      >
                        <div
                          class="chat-dropdown__box__tabs nav-tabs flex px-3 mt-5"
                        >
                          <a
                            data-toggle="tab"
                            data-target="#smile"
                            href="javascript:;"
                            class="py-2 flex justify-center flex-1 rounded hover:bg-gray-200 dark:hover:bg-dark-2 active"
                          >
                            <svg
                              class="w-4 h-4"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 496 512"
                            >
                              <path
                                fill="currentColor"
                                d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm4 72.6c-20.8 25-51.5 39.4-84 39.4s-63.2-14.3-84-39.4c-8.5-10.2-23.7-11.5-33.8-3.1-10.2 8.5-11.5 23.6-3.1 33.8 30 36 74.1 56.6 120.9 56.6s90.9-20.6 120.9-56.6c8.5-10.2 7.1-25.3-3.1-33.8-10.1-8.4-25.3-7.1-33.8 3.1z"
                              ></path>
                            </svg>
                          </a>
                          <a
                            data-toggle="tab"
                            data-target="#cat"
                            href="javascript:;"
                            class="py-2 flex justify-center flex-1 rounded hover:bg-gray-200 dark:hover:bg-dark-2"
                          >
                            <svg
                              class="w-4 h-4"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M290.59 192c-20.18 0-106.82 1.98-162.59 85.95V192c0-52.94-43.06-96-96-96-17.67 0-32 14.33-32 32s14.33 32 32 32c17.64 0 32 14.36 32 32v256c0 35.3 28.7 64 64 64h176c8.84 0 16-7.16 16-16v-16c0-17.67-14.33-32-32-32h-32l128-96v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V289.86c-10.29 2.67-20.89 4.54-32 4.54-61.81 0-113.52-44.05-125.41-102.4zM448 96h-64l-64-64v134.4c0 53.02 42.98 96 96 96s96-42.98 96-96V32l-64 64zm-72 80c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16zm80 0c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16z"
                              ></path>
                            </svg>
                          </a>
                          <a
                            data-toggle="tab"
                            data-target="#coffee"
                            href="javascript:;"
                            class="py-2 flex justify-center flex-1 rounded hover:bg-gray-200 dark:hover:bg-dark-2"
                          >
                            <svg
                              class="w-4 h-4"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 640 512"
                            >
                              <path
                                fill="currentColor"
                                d="M192 384h192c53 0 96-43 96-96h32c70.6 0 128-57.4 128-128S582.6 32 512 32H120c-13.3 0-24 10.7-24 24v232c0 53 43 96 96 96zM512 96c35.3 0 64 28.7 64 64s-28.7 64-64 64h-32V96h32zm47.7 384H48.3c-47.6 0-61-64-36-64h583.3c25 0 11.8 64-35.9 64z"
                              ></path>
                            </svg>
                          </a>
                          <a
                            data-toggle="tab"
                            data-target="#futbol"
                            href="javascript:;"
                            class="py-2 flex justify-center flex-1 rounded hover:bg-gray-200 dark:hover:bg-dark-2"
                          >
                            <svg
                              class="w-4 h-4"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zm-48 0l-.003-.282-26.064 22.741-62.679-58.5 16.454-84.355 34.303 3.072c-24.889-34.216-60.004-60.089-100.709-73.141l13.651 31.939L256 139l-74.953-41.525 13.651-31.939c-40.631 13.028-75.78 38.87-100.709 73.141l34.565-3.073 16.192 84.355-62.678 58.5-26.064-22.741-.003.282c0 43.015 13.497 83.952 38.472 117.991l7.704-33.897 85.138 10.447 36.301 77.826-29.902 17.786c40.202 13.122 84.29 13.148 124.572 0l-29.902-17.786 36.301-77.826 85.138-10.447 7.704 33.897C442.503 339.952 456 299.015 456 256zm-248.102 69.571l-29.894-91.312L256 177.732l77.996 56.527-29.622 91.312h-96.476z"
                              ></path>
                            </svg>
                          </a>
                          <a
                            data-toggle="tab"
                            data-target="#building"
                            href="javascript:;"
                            class="py-2 flex justify-center flex-1 rounded hover:bg-gray-200 dark:hover:bg-dark-2"
                          >
                            <svg
                              class="w-4 h-4"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="currentColor"
                                d="M128 148v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm140 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-128 96h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm128 0h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-76 84v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm76 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm180 124v36H0v-36c0-6.6 5.4-12 12-12h19.5V24c0-13.3 10.7-24 24-24h337c13.3 0 24 10.7 24 24v440H436c6.6 0 12 5.4 12 12zM79.5 463H192v-67c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v67h112.5V49L80 48l-.5 415z"
                              ></path>
                            </svg>
                          </a>
                          <a
                            data-toggle="tab"
                            data-target="#lightbulb"
                            href="javascript:;"
                            class="py-2 flex justify-center flex-1 rounded hover:bg-gray-200 dark:hover:bg-dark-2"
                          >
                            <svg
                              class="w-4 h-4"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 352 512"
                            >
                              <path
                                fill="currentColor"
                                d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"
                              ></path>
                            </svg>
                          </a>
                          <a
                            data-toggle="tab"
                            data-target="#music"
                            href="javascript:;"
                            class="py-2 flex justify-center flex-1 rounded hover:bg-gray-200 dark:hover:bg-dark-2"
                          >
                            <svg
                              class="w-4 h-4"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M511.99 32.01c0-21.71-21.1-37.01-41.6-30.51L150.4 96c-13.3 4.2-22.4 16.5-22.4 30.5v261.42c-10.05-2.38-20.72-3.92-32-3.92-53.02 0-96 28.65-96 64s42.98 64 96 64 96-28.65 96-64V214.31l256-75.02v184.63c-10.05-2.38-20.72-3.92-32-3.92-53.02 0-96 28.65-96 64s42.98 64 96 64 96-28.65 96-64l-.01-351.99z"
                              ></path>
                            </svg>
                          </a>
                        </div>
                        <div class="tab-content overflow-hidden mt-5">
                          <div
                            id="smile"
                            class="h-full tab-content__pane active"
                          >
                            <div class="font-medium px-3">Smileys & People</div>
                            <div
                              class="h-full pb-10 px-2 overflow-y-auto scrollbar-hidden mt-2"
                            >
                              <div class="grid grid-cols-8 text-2xl">
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😁
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😂
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😃
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😄
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😅
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😆
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😉
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😊
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😋
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😎
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😍
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😘
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😗
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😙
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😚
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☺️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙂
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤗
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤩
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😶
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙄
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😏
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😥
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😮
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😯
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😪
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😫
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😴
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😌
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😛
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😜
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😝
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤤
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙃
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😲
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☹️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙁
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😖
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😞
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😟
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😤
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😢
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😭
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😩
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤯
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😬
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😰
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😱
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😳
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤪
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😵
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😡
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😠
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤬
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😷
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤢
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤮
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😇
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤠
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤡
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤥
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤫
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤭
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😈
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👿
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👹
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👺
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☠️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👻
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👽
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👾
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤖
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💩
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😺
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😸
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😹
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😻
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😼
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😽
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😿
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  😾
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙈
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙉
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙊
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👶
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👴
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👵
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;⚕️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;⚕️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;🎓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;🎓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;🏫
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;🏫
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;⚖️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;⚖️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;🌾
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;🌾
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;🍳
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;🍳
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;🔧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;🔧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;🏭
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;🏭
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;💼
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;💼
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;🔬
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;🔬
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;💻
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;💻
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;🎤
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;🎤
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;🎨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;🎨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;✈️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;✈️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;🚀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;🚀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;🚒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;🚒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👮
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👮&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👮&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕵️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕵️&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕵️&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💂
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💂&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💂&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👷
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👷&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👷&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤴
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👸
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👳
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👳&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👳&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👲
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👱
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👱&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👱&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤵
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👰
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤰
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤱
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👼
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎅
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤶
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧙
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧙&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧙&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧚
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧚&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧚&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧛
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧛&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧛&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧜
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧜&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧜&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧝
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧝&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧝&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧞
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧞&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧞&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧟
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧟&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧟&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙍
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙍&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙍&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙎
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙎&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙎&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙅
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙅&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙅&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙆
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙆&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙆&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💁
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💁&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💁&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙋
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙋&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙋&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙇
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙇&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙇&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤦&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤦&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤷
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤷&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤷&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💆
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💆&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💆&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💇
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💇&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💇&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚶
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚶&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚶&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏃
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏃&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏃&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💃
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕺
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👯
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👯&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👯&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧖
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧖&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧖&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧗
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧗&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧗&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧘
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧘&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧘&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛌
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕴️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗣️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👤
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👥
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤺
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏇
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛷️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏂
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏌️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏌️&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏌️&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏄
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏄&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏄&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚣&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚣&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏊
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏊&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏊&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛹️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛹️&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛹️&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏋️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏋️&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏋️&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚴
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚴&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚴&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚵
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚵&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚵&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏎️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏍️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤸
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤸&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤸&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤼
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤼&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤼&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤽
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤽&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤽&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤾
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤾&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤾&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤹
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤹&zwj;♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤹&zwj;♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👫
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👬
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👭
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💏
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;❤️&zwj;💋&zwj;👨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;❤️&zwj;💋&zwj;👨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;❤️&zwj;💋&zwj;👩
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;❤️&zwj;👨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;❤️&zwj;👨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;❤️&zwj;👩
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👪
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;👩&zwj;👦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;👩&zwj;👧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;👩&zwj;👧&zwj;👦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;👩&zwj;👦&zwj;👦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;👩&zwj;👧&zwj;👧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;👨&zwj;👦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;👨&zwj;👧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;👨&zwj;👧&zwj;👦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;👨&zwj;👦&zwj;👦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;👨&zwj;👧&zwj;👧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;👩&zwj;👦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;👩&zwj;👧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;👩&zwj;👧&zwj;👦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;👩&zwj;👦&zwj;👦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;👩&zwj;👧&zwj;👧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;👦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;👦&zwj;👦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;👧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;👧&zwj;👦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👨&zwj;👧&zwj;👧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;👦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;👦&zwj;👦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;👧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;👧&zwj;👦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👩&zwj;👧&zwj;👧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤳
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💪
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👈
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👉
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☝️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👆
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🖕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👇
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ✌️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤞
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🖖
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤘
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤙
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🖐️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ✋
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👌
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👍
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👎
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ✊
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👊
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤛
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤜
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤚
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👋
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤟
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ✍️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👏
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙌
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤲
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🙏
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🤝
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💅
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👂
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👃
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👁️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👁️&zwj;🗨️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧠
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👅
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👄
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💋
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💘
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ❤️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💖
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💗
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💙
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💚
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💛
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧡
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💜
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🖤
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💝
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💞
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💟
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ❣️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💌
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💤
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💢
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💥
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💫
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💬
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗨️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗯️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💭
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕳️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕶️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👖
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧤
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧥
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👗
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👘
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👙
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👚
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👛
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👜
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👝
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛍️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👞
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👟
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👠
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👡
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👢
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  👒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎩
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧢
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛑️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📿
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💄
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💍
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💎
                                </button>
                              </div>
                            </div>
                          </div>
                          <div id="cat" class="h-full tab-content__pane">
                            <div class="font-medium px-3">Animals & Nature</div>
                            <div
                              class="h-full pb-10 px-2 overflow-y-auto scrollbar-hidden mt-2"
                            >
                              <div class="grid grid-cols-8 text-2xl">
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐵
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦍
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐶
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐩
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐺
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦊
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐱
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐈
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦁
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐯
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐅
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐆
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐴
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐎
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦄
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦌
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐮
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐂
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐃
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐄
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐷
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐖
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐗
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐽
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐏
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐪
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐫
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐘
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦏
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐭
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐁
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐹
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐰
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐇
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐿️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦇
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐻
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐼
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐾
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦃
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐤
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐥
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕊️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦅
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦆
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦉
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐸
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐊
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐢
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦎
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐍
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐲
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐉
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦖
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐳
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐋
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐬
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐟
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐠
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐡
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦈
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐙
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐚
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐌
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦋
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐛
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐜
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐝
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🐞
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦗
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕷️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕸️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🦂
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌸
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💮
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏵️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌹
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌺
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌻
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌼
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌷
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌱
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌲
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌳
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌴
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌵
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌾
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌿
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☘️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍁
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍂
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍃
                                </button>
                              </div>
                            </div>
                          </div>
                          <div id="coffee" class="h-full tab-content__pane">
                            <div class="font-medium px-3">Food & Drink</div>
                            <div
                              class="h-full pb-10 px-2 overflow-y-auto scrollbar-hidden mt-2"
                            >
                              <div class="grid grid-cols-8 text-2xl">
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍇
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍈
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍉
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍊
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍋
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍌
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍍
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍎
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍏
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥝
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍅
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥥
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍆
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌽
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌶️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍄
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥜
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌰
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍞
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥖
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥞
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🧀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍖
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍗
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥩
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍟
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌭
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥪
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌮
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌯
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥙
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥚
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍳
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥘
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍲
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥗
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍿
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥫
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍱
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍘
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍙
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍚
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍛
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍜
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍝
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍠
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍢
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍤
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍥
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍡
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥟
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥠
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥡
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍩
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍪
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎂
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍰
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍫
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍬
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍭
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍮
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍯
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍼
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥛
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍵
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍶
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍾
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍷
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍸
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍹
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍺
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍻
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥂
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥃
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥤
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥢
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍽️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🍴
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥄
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔪
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏺
                                </button>
                              </div>
                            </div>
                          </div>
                          <div id="futbol" class="h-full tab-content__pane">
                            <div class="font-medium px-3">Activities</div>
                            <div
                              class="h-full pb-10 px-2 overflow-y-auto scrollbar-hidden mt-2"
                            >
                              <div class="grid grid-cols-8 text-2xl">
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎃
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎄
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎆
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎇
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ✨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎈
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎉
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎊
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎋
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎍
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎎
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎏
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎁
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎗️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎟️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎫
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎖️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏆
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏅
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥇
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥈
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥉
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⚽
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⚾
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏈
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏉
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎾
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎱
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎳
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏏
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏸
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥊
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥋
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥅
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎯
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛳
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛸️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎽
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎿
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛷
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥌
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎮
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕹️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎲
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♠️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♥️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♦️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♣️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🃏
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🀄
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎴
                                </button>
                              </div>
                            </div>
                          </div>
                          <div id="building" class="h-full tab-content__pane">
                            <div class="font-medium px-3">Travel & Places</div>
                            <div
                              class="h-full pb-10 px-2 overflow-y-auto scrollbar-hidden mt-2"
                            >
                              <div class="grid grid-cols-8 text-2xl">
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌍
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌎
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌏
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗺️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗾
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏔️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛰️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌋
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗻
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏕️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏖️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏜️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏝️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏞️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏟️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏛️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏗️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏘️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏙️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏚️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏠
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏡
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏢
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏤
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏥
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏩
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏪
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏫
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏬
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏭
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏯
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏰
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗼
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗽
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛪
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕌
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕍
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛩️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕋
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛲
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛺
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌁
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌃
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌄
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌅
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌆
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌇
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌉
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♨️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌌
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎠
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎡
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎢
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💈
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎪
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎭
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🖼️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎰
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚂
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚃
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚄
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚅
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚆
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚇
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚈
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚉
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚊
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚝
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚞
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚋
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚌
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚍
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚎
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚖
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚗
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚘
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚙
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚚
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚛
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚜
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚲
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛴
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛵
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚏
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛣️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛤️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛽
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚥
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⚓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛵
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛶
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚤
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛳️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛴️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛥️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚢
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ✈️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛩️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛫
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛬
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💺
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚁
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚟
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚠
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚡
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛰️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛸
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛎️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚪
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛏️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛋️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚽
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚿
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛁
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⌛
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⏳
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⌚
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⏰
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⏱️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⏲️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕰️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕛
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕜
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕝
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕞
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕟
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕠
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕡
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕖
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕢
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕗
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕘
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕤
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕙
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕥
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕚
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌖
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌗
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌘
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌙
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌚
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌛
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌜
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌡️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌝
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌞
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⭐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌟
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌠
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☁️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛅
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛈️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌤️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌥️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌦️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌧️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌨️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌩️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌪️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌫️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌬️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌈
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌂
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛱️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⚡
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ❄️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☃️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛄
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☄️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔥
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🌊
                                </button>
                              </div>
                            </div>
                          </div>
                          <div id="lightbulb" class="h-full tab-content__pane">
                            <div class="font-medium px-3">Objects</div>
                            <div
                              class="h-full pb-10 px-2 overflow-y-auto scrollbar-hidden mt-2"
                            >
                              <div class="grid grid-cols-8 text-2xl">
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔇
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔈
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔉
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔊
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📢
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📯
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎼
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎵
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎶
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎙️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎚️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎛️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎤
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📻
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎷
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎸
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎹
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎺
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎻
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🥁
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📱
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📲
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☎️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📞
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📟
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📠
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔋
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔌
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💻
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🖥️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🖨️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⌨️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🖱️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🖲️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💽
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💾
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💿
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎥
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎞️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📽️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎬
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📺
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📷
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📸
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📹
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📼
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔍
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔎
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔬
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔭
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📡
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕯️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💡
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏮
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📖
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📗
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📘
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📙
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📚
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📃
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📜
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📄
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📰
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗞️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔖
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏷️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💰
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💴
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💵
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💶
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💷
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💸
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💳
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💹
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💱
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💲
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ✉️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📩
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📤
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📥
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📫
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📪
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📬
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📭
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📮
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗳️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ✏️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ✒️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🖋️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🖊️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🖌️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🖍️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📝
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💼
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📁
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📂
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📅
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📆
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗒️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗓️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📇
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📈
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📉
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📊
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📋
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📌
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📍
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📎
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🖇️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📏
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ✂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗃️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗄️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗑️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔏
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗝️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔨
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛏️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⚒️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛠️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗡️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⚔️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔫
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏹
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛡️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔩
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⚙️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗜️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⚗️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⚖️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔗
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛓️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💉
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💊
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚬
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⚰️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⚱️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🗿
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛢️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔮
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛒
                                </button>
                              </div>
                            </div>
                          </div>
                          <div id="music" class="h-full tab-content__pane">
                            <div class="font-medium px-3">Symbols</div>
                            <div
                              class="h-full pb-10 px-2 overflow-y-auto scrollbar-hidden mt-2"
                            >
                              <div class="grid grid-cols-8 text-2xl">
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🏧
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚮
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚰
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♿
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚹
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚺
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚻
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚼
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚾
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛂
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛃
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛄
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛅
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⚠️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚸
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚫
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚳
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚭
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚯
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚱
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🚷
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📵
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔞
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☢️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☣️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⬆️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ↗️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ➡️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ↘️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⬇️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ↙️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⬅️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ↖️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ↕️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ↔️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ↩️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ↪️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⤴️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⤵️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔃
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔄
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔙
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔚
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔛
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔜
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔝
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🛐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⚛️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕉️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ✡️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☸️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☯️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ✝️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☦️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☪️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☮️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🕎
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔯
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♈
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♉
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♊
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♋
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♌
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♍
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♎
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♏
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⛎
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔀
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔁
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔂
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ▶️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⏩
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⏭️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⏯️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ◀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⏪
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⏮️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔼
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⏫
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔽
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⏬
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⏸️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⏹️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⏺️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⏏️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🎦
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔅
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔆
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📶
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📳
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📴
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♀️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⚕️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ♻️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⚜️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔱
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  📛
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔰
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⭕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ✅
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ☑️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ✔️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ✖️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ❌
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ❎
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ➕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ➖
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ➗
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ➰
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ➿
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  〽️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ✳️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ✴️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ❇️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ‼️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⁉️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ❓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ❔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ❕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ❗
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  〰️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ©️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ®️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ™️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  #️⃣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  *️⃣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  0️⃣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  1️⃣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  2️⃣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  3️⃣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  4️⃣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  5️⃣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  6️⃣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  7️⃣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  8️⃣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  9️⃣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔟
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💯
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔠
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔡
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔢
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔣
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔤
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🅰️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🆎
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🅱️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🆑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🆒
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🆓
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ℹ️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🆔
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  Ⓜ️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🆕
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🆖
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🅾️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🆗
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🅿️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🆘
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🆙
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🆚
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🈁
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🈂️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🈷️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🈶
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🈯
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🉐
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🈹
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🈚
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🈲
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🉑
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🈸
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🈴
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🈳
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ㊗️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ㊙️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🈺
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🈵
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ▪️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ▫️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ◻️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ◼️
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ◽
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ◾
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⬛
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⬜
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔶
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔷
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔸
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔹
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔺
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔻
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  💠
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔘
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔲
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔳
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⚪
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  ⚫
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" @click="setSmile"
                                >
                                  🔴
                                </button>
                                <button
                                  class="rounded focus:outline-none hover:bg-gray-200 dark:hover:bg-dark-2" v-on:click="setSmile(this)"
                                >
                                  🔵
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="dropdown inline-block" data-placement="top">
                    <div class="dropdown-toggle w-4 h-4 sm:w-5 sm:h-5 relative text-gray-500 mr-3 sm:mr-5" :style="{'cursor': 'pointer'}">
                      <MicIcon class="w-full h-full" />
                    </div>
                    <div class="dropdown-box">
                        <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-40 h-40 text-theme-9" :style="{'right': '0', 'padding-top': '0', 'position': 'relative'}"> 
                          <XIcon class="w-5 h-5 text-theme-8" :style="{'right': '5px', 'top': '5px', 'position': 'absolute', 'cursor': 'pointer'}"/>

                          <a v-if="audioRecord === false" href="javascript:;" @click="startRecord" class="w-20 h-20 block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                          :style="{'margin-left': '32px', 'margin-top': '20px', 'position': 'absolute'}">
                            <PlayIcon class="w-full h-full"/>
                          </a> 

                          <a v-if="audioRecord === true" href="javascript:;" @click="stopRecord" class="text-theme-6 w-20 h-20 block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                          :style="{'margin-left': '32px', 'margin-top': '20px', 'position': 'absolute'}">
                            <StopCircleIcon class="w-full h-full"/>
                          </a> 

                          <span class="text-theme-7" :style="{'bottom': '0', 'position': 'absolute', 'font-size': '25px', 'margin-left': '25px', 'margin-bottom': '10px'}">00:00:00</span>
                        </div>
                    </div>
                </div>

                <div class="w-4 h-4 sm:w-5 sm:h-5 relative text-gray-500 mr-3 sm:mr-5" >
                  <PaperclipIcon class="w-full h-full" />
                  <input
                    type="file"
                    name="file_upload"
                    @change="uploadSmsImage"
                    class="w-full h-full top-0 left-0 absolute opacity-0"
                  >
                </div>

                <div class="w-4 h-4 sm:w-5 sm:h-5 relative text-gray-500 mr-3 sm:mr-5">
                  <a
                  href="javascript:;"
                  class="flex-none flex items-center justify-center"
                  @click="sendMessasage"
                  title="Мессеж илгээх"
                >
                  <SendIcon class="w-full h-full text-theme-7"/>
                </a>
                </div>  

              </div>
            </div>
          </div>
          <!-- END: Chat Active -->
          <!-- BEGIN: Chat Default -->
          <div v-else class="h-full flex items-center">
            <div class="mx-auto text-center">
              <div
                class="w-16 h-16 flex-none image-fit rounded-full overflow-hidden mx-auto"
              >
                <img
                  alt="Midone Tailwind HTML Admin Template"
                  :src="ops.image"
                />
              </div>
              <div class="mt-3">
                <div class="font-medium">
                  Сайн уу, {{ ops.name }}!
                </div>
                <div class="text-gray-600 mt-1">
                  Мессеж бичиж эхлэхийн тулд чатаа сонгоно уу.
                </div>
              </div>
            </div>
          </div>
          <!-- END: Chat Default -->
        </div>
      </div>
      <!-- END: Chat Content -->
    </div>
  </div>
</template>
<script>
URL = window.URL || window.webkitURL;
var gumStream; var rec; var input; 
var AudioContext = window.AudioContext || window.webkitAudioContext;
var audioContext = new AudioContext;
import Toastify from "toastify-js";
var Recorder = require('@/libs/Recorder');
// import Recorder from '@/mylib'
export default {
  mounted() {
    // this.$socket.emit('send', 'message');
    this.setUserInfo();
    this.getChatRooms();
  },
  data() {
    return {
      chatBox: false,
      audioRecord: false,
      ops: {
        image : require(`@/assets/images/menu/sandwich.png`),
        name : '',
        email : ''
      },
      chat: {
        roomMessages: [],
        rooms: [],
        allMessage: [],
        roomId: '',
        currentMessage: ''
      },
      user : {
        name: '',
        image: '',
        id: ''
      },
      client: {
        image: '',
        name: '',
        status: 'Offline'
      },
      file: {
        src: null,
      }
    };
  },
  methods: {
    uploadSmsImage() {
      var rts = this;
      var file = document
        .querySelector('input[type=file]')
        .files[0];
      
      var reader = new FileReader();
      
      reader.onload = function(e) {
        let src = e.target.result;    
        rts.sendFile(src, file.type.split('/')[0]);
      };
      reader.onerror = function(error) {
        alert(error);
      };
      reader.readAsDataURL(file);    
      // this.sendFile(file.type.split('/')[0]);
    },
    getOnlineUsers() {
      console.log('hello');
    },
    dateFormat(date) {
      let dt = new Date(date);
      var ms = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return dt.getDate() + ' ' + ms[dt.getMonth()] + ' ' + dt.getFullYear() + ' ' + dt.getHours()+':'+ (dt.getMinutes()<10?'0':'') + dt.getMinutes();
    },
    setUserInfo() {
      var users = localStorage.getItem('user');
      if(users) {
        var user = JSON.parse(users);
        this.ops.image = this.$appUrl + '/images/ops/' + user.avatar;
        this.ops.name = user.lastname + ' ' + user.firstname;
        this.ops.email = user.email
      }
    },
    showChatBox(roomId) {
      this.chat.roomId = roomId;
      
      this.$socket.emit('joinRoom', {
        chatroomId: roomId,
        ops: true
      });

      this.getRoomChats();
      this.chatBox = false;
    },
    setSmile(event) {
      this.chat.currentMessage = this.chat.currentMessage + event.toElement.innerHTML.trim();
    },
    getChatRooms() {
      var token = localStorage.getItem('token');
      var rts = this;
      this.$axios.get(this.$appUrl+'/chatroom/all', {
          headers: {
            "Authorization": `Bearer ${token}`
          }
      }).then(function(data){
        if(data.data.status == 200) {
          rts.chat.rooms = data.data.chatrooms;
          rts.chat.allMessage = data.data.messages;
          console.log(data.data.chatrooms);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    currentClient() {
      this.chat.rooms.forEach(element => {
        if(element.name === this.chat.roomId) {
          this.client.name = element.user.lastname + ' ' +element.user.firstname;
          element.user.Online == true? this.client.status = 'Online': this.client.status = 'Offline';
          this.client.image = this.$appUrl + '/images/users/' + element.user.avatar
        }
      });
    },
    getRoomChats() {
      var token = localStorage.getItem('token');
      var rts = this;
      this.$axios.get(this.$appUrl+'/chatroom/'+rts.chat.roomId, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
      }).then(function(data){
        rts.currentClient();
        rts.chat.roomMessages = data.data.roomMessages;
        console.log(data.data.roomMessages);
        rts.chatBox = true;
      }).catch(error => {
        console.log(error);
      });
    },
    sendMessasage() {
      var msg = this.chat.currentMessage;
      this.chat.currentMessage = '';
      var rts = this;
      this.$socket.emit('chatroomMessage', {
        chatroomId: rts.chat.roomId,
        message: msg,
        ops: true,
        file: false,
        type: 'none'
      });
    },
    sendFile(file, type) {
      var rts = this;
      console.log('hello');
      this.$socket.emit('chatroomMessage', {
        chatroomId: rts.chat.roomId,
        message: file,
        ops: true,
        file: true,
        type: type
      });

    },
    startRecord() {
      var rts = this;
      rts.audioRecord = true;

      var constraints = {
          audio: true,
          video: false
      } 
      navigator.mediaDevices.getUserMedia(constraints).then(function(stream) {
          console.log("getUserMedia() success, stream created, initializing Recorder.js ..."); 
          
          /* assign to gumStream for later use */
          gumStream = stream;
          /* use the stream */
          input = audioContext.createMediaStreamSource(stream);

          /* Create the Recorder object and configure to record mono sound (1 channel) Recording 2 channels will double the file size */
          rec = new Recorder(input, {
              numChannels: 1
          }) 
          //start the recording process 
          rec.record()
          console.log("Recording started");
      }).catch(function(err) {
          rts.audioRecord = false;
          Toastify({
            text: err,
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "top",
            position: "center",
            backgroundColor: "#216fcf",
            stopOnFocus: true
          }).showToast();
      });
    },
    stopRecord() {
      this.audioRecord = false;
        rec.stop(); //stop microphone access 
        gumStream.getAudioTracks()[0].stop();
        rec.exportWAV(this.createDownloadLink);
    },
    createDownloadLink(blob) {  
      console.log(blob);
      var rts = this;
      const fd = new FormData();
      const token = localStorage.getItem('token');
      let filename = new Date().toISOString() + '.wav';
      fd.append('voice', blob, filename);
      this.$axios.post(this.$appUrl+'/chatroom/upload-audio', 
        fd,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        )
        .then(function(data) {
          console.log("===========",data);
          let file = data.data.file;
          rts.sendFile(file, "audio");
          
        });
    }
  },
  sockets: {
    onMessage: function (data) {
      this.chat.roomMessages.push(data);
    },
    onlineUser: function(data) {
      this.chat.rooms.forEach(element => {
        if(element.name === data.id) {
          element.user.Online = true;
        }
      });
    },
    offlineUser: function(data) {
      this.chat.rooms.forEach(element => {
        if(element.name === data.id) {
          element.user.Online = false;
        }
      });
    },
    newMessage: function(data) {

      let ex = this.chat.allMessage.some(function(field) {
        return field.chatroom === data.chatroom
      });

      if(!ex) {
          let ex_user = this.chat.rooms.some(function(field) {
            return field.name === data.chatroom
          });

          if (ex_user) {
            this.chat.allMessage.push(data);
            console.log(this.chat.allMessage);
          }

      } else {
          this.chat.allMessage.forEach(element => {
          if(element.chatroom === data.chatroom) {
            element.message = data.message;
            element.operator = data.operator;
            element.seen = data.seen;
          }
        });
      }
    }
  }
};
</script>
