<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">Текст бүртгэх</h2>
    </div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-10 sm:py-10 mt-5">
      <div
        class="px-5 sm:px-20 pt-10 border-t border-gray-200 dark:border-dark-5"
      >
        <div class="font-medium text-base">Шаардлагатай мэдээлэл</div>
      <form @submit.prevent="submit">
        <div class="grid grid-cols-12 gap-4 row-gap-5 mt-5">

          <div class="intro-y col-span-3 sm:col-span-3">
            <div class="mb-2">Төрөл</div>
            <el-select v-model="info.type" required class="w-full" placeholder="e.g: Admin" @change="checkType">
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div class="intro-y col-span-3 sm:col-span-3">
            <div class="mb-2">Хэл</div>
            <el-select v-model="info.language" required class="w-full" placeholder="e.g: Admin">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :disabled="info.disableLevel"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          
          <div class="intro-y col-span-3 sm:col-span-3">
            <div class="mb-2">Түвшин</div>
            <el-select v-model="info.level" required class="w-full" placeholder="e.g: Admin">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :disabled="info.disableLevel"
                :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div class="intro-y col-span-3 sm:col-span-3">
            <div class="mb-2">Огноо</div>
              <el-date-picker
                v-model="dateRange"
                type="daterange"
                :style="{'max-width': '100%'}"
                range-separator="-"
                :disabled="info.dateDisabled"
                align="right"
                start-placeholder="Эхлэх огноо"
                end-placeholder="Дуусах огноо">
              </el-date-picker>
          </div>

          <div class="intro-y col-span-3 sm:col-span-3" :hidden="info.dateDisabled">
            <div class="mb-2">Уралдааны нэр</div>
            <el-input
              placeholder="Нэр оруулна уу (max: 50 тэмдэгт)"
              v-model="raceName"
              class="mb-5"
              maxlength="50"
              clearable>
            </el-input>
          </div>

          <div class="intro-y col-span-3 sm:col-span-3" :hidden="info.dateDisabled">
            <div class="mb-2">Дээд WPM</div>
            <el-input-number v-model="raceWpm" :style="{'width': '100%'}" :min="1" placeholder="Дээд WPM"></el-input-number>
          </div>

          <div class="intro-y col-span-3 sm:col-span-3" :hidden="info.dateDisabled">
            <div class="mb-2">Доод оноо</div>
            <el-input-number v-model="raceMinPoint" :style="{'width': '100%'}" :min="1" placeholder="Оролцох доод оноо"></el-input-number>
          </div>

          <div class="intro-y col-span-3 sm:col-span-3" :hidden="info.dateDisabled">
            <div class="mb-2">Дээд оноо</div>
            <el-input-number v-model="raceMaxPoint" :style="{'width': '100%'}" placeholder="Оролцох дээд оноо" :min="1"></el-input-number>
          </div>

          <div class="intro-y col-span-12 sm:col-span-12" :hidden="info.dateDisabled">

            <div class="box" :style="{'height': '150px', 'padding-top': '50px'}">
              <input type="file" id="textImage" accept="image/*" hidden @change="onProfileImageSelected" />
              <button type="button" @click="imageTrigger" class="button mr-2 mb-2 flex border text-gray-700 dark:border-dark-5 dark:text-gray-300 whitespace-no-wrap" :style="{'margin': 'auto'}">
                  <UploadIcon class="w-5 h-5 mr-2" /> Зураг сонгох
              </button>
              <div align="center" class="mt-1">{{file.name}}</div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-12">
            <div class="mb-2">Текст</div>
            <textarea
              type="text"
              rows="3"
              class="input w-full border flex-1"
              required
              placeholder="e.g: Hello ..."
              v-model="info.text"
              :maxlength="maxlength"
            >
            </textarea>
          </div>
          
          <div
            class="intro-y col-span-12 flex items-center justify-center sm:justify-end"
          >
            <button
              class="button w-24 justify-center block bg-theme-1 text-white ml-2"
              type="submit"
              :disabled="isDisabled"
            >
              Хадгалах
            </button>
          </div>
        </div>
      </form>
      </div>
    </div>
    <!-- END: Wizard Layout -->
  </div>
</template>
<script>
import vue2Dropzone from "vue2-dropzone";
export default {
  components: {
    VueDropzone: vue2Dropzone
  },
  data() {
    return {
      maxlength: 400,
      dateRange: '',
      file: {
        name: '',
        selectedFile: ''
      },
      info: {
        text: '',
        level: 'Анхан',
        language: 'Монгол',
        type: 'Хичээл',
        disableLevel : false,
        dateDisabled: true
      },
      isDisabled: false,
      raceName: '',
      raceMinPoint: 0,
      raceWpm: 0,
      raceMaxPoint: 0,
      options: [{
          value: 'Анхан',
          label: 'Анхан'
        }, {
          value: 'Дунд',
          label: 'Дунд'
        }, {
          value: 'Ахисан',
          label: 'Ахисан'
        }],
        options2: [{
          value: 'Монгол',
          label: 'Монгол'
        }, {
          value: 'English',
          label: 'English'
        }],
        options3: [{
          value: 'Дадлага',
          label: 'Дадлага'
        }, {
          value: 'Шагналтай уралдаан',
          label: 'Шагналтай уралдаан'
        },{
          value: 'Онлайн уралдаан',
          label: 'Онлайн уралдаан'
        }]
    }
  },  
  mounted() {
    
  },
  methods: {
    async firstImage() {
      console.log("ywlaaa");
      const token = localStorage.getItem('token');
      const fd = new FormData();
      fd.append('photo', this.file.selectedFile, this.file.name);

      let img = this.$axios.post(this.$appUrl+'/api/admin/add-text-image', 
        fd,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        ).then(function(data) {
          return data.data.img;
        });
      return await img;
    },
    async submit() {
      console.log("ajillaa")
      let rts = this;
      const token = localStorage.getItem('token');
      if(this.info.type == 'Шагналтай уралдаан') {
        if(this.file.name != '') {
          if(this.raceName != '' && this.raceWPM != 0) {

            if(this.dateRange != '') {
              console.log("end");
              let img = await this.firstImage();
              console.log("ywsn");
              this.$axios({
              method: 'POST',
              url: this.$appUrl+'/api/admin/add-text', 
              headers: {
                    'Authorization': `Bearer ${token}`
                },
              data: {
                  type: this.info.type,
                  level: this.info.level,
                  language: this.info.language,
                  text: this.info.text,
                  date: this.dateRange,
                  image: img,
                  name: this.raceName,
                  minPoint: this.raceMinPoint,
                  maxPoint: this.raceMaxPoint,
                  limitWpm: this.raceWpm
                }
              })
               .then(function(data) {
                if(rts.info.type == 'Хичээл') {
                  if (data.data.result == 'success') {
                    rts.$message({
                      showClose: true,
                      message: 'Амжилттай хадгалагдлаа',
                      type: 'success'
                    });
                    rts.$router.push('/texts');
                  }
                } else {
                  if(data.data.result == 'success') {
                    rts.$message({
                      showClose: true,
                      message: 'Амжилттай хадгалагдлаа',
                      type: 'success'
                    });
                    rts.$router.push('/race-text');
                  } else {
                    rts.$message({
                      showClose: true,
                      message: 'Идэвхитэй уралдаан байна',
                      type: 'error'
                    });
                  }
                }
              }).catch(error => {
                console.log(error);
              });
            } else {
              rts.$message({
                showClose: true,
                message: 'Огноогоо оруулна уу',
                type: 'error'
              });
            }
          } else {
            rts.$message({
              showClose: true,
              message: 'Нэр эсвэл WPM оруулаагүй байна',
              type: 'error'
            });
          }
        } else {
          rts.$message({
            showClose: true,
            message: 'Зураг сонгоно уу',
            type: 'error'
          });
        }
      } else {
        this.$axios({
        method: 'POST',
        url: this.$appUrl+'/api/admin/add-text', 
        headers: {
              'Authorization': `Bearer ${token}`
            },
        data: {
          type: this.info.type,
          level: this.info.level,
          language: this.info.language,
          text: this.info.text,
          date: this.dateRange
        }
        }).then(function(data) {
          if(rts.info.type == 'Хичээл') {
            if (data.data.result == 'success') {
              rts.$message({
                showClose: true,
                message: 'Амжилттай хадгалагдлаа',
                type: 'success'
              });
              rts.$router.push('/texts');
            }
          } else {
            if(data.data.result == 'success') {
              rts.$message({
                showClose: true,
                message: 'Амжилттай хадгалагдлаа',
                type: 'success'
              });
              rts.$router.push('/race-text');
            } else {
              rts.$message({
                showClose: true,
                message: 'Идэвхитэй уралдаан байна',
                type: 'error'
              });
            }
          }
        }).catch(error => {
          console.log("aldaa");
          console.log(error);
        });
      }

    },
    checkType() {
      this.info.text = '';
      if(this.info.type == 'Хичээл') {
        this.maxlength = 400;
        this.info.disableLevel = false;
        this.info.dateDisabled = true;
      } else if(this.info.type == 'Шагналтай уралдаан') {
        this.maxlength = 450;
        this.info.disableLevel = true;
        this.info.dateDisabled = false;
      } else if (this.info.type == 'Онлайн уралдаан') {
        this.maxlength = 450;
        this.info.disableLevel = false;
        this.info.dateDisabled = true;
      }
    },
    onProfileImageSelected (event) {
      const file = event.target.files[0];
      this.file.selectedFile = file;
      this.file.name = file.name;
    },
    imageTrigger() {
      let el = document.getElementById("textImage");
      el.click();
    }
   
  }
}
</script>