<template>
  <div>
    <div class="flex col-span-12 mt-6">
      <div class="intro-y block sm:flex items-center h-10">
          <h2 class="text-lg font-medium truncate mr-5">Шинэ хичээл үүсгэх</h2>
          <div class="flex items-center sm:ml-auto mt-3 sm:mt-0">
            <button
              class="button box flex items-center text-gray-700 dark:text-gray-300"
              @click="this.showNewLessonGropu"
            >
              <HomeIcon class="hidden sm:block w-4 h-4 mr-2" />
              Хичээлийн шинэ групп үүсгэх
            </button>
          </div>
        </div>
    </div>

    <div class="intro-y box py-10 sm:py-10 mt-5">
      <div
        class="px-5 sm:px-20 pt-10 border-t border-gray-200 dark:border-dark-5"
      >
        <div class="font-medium text-base">Шаардлагатай мэдээлэл</div>
      <form @submit.prevent="submit">
        <div class="grid grid-cols-12 gap-4 row-gap-5 mt-5">

          <div class="intro-y col-span-2 sm:col-span-2">
            <div class="mb-2">Төрөл</div>
            <el-select v-model="info.type" required class="w-full" placeholder="e.g: boxed" @change="checkType">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div class="intro-y col-span-2 sm:col-span-2">
            <div class="mb-2">Хэл</div>
            <el-select v-model="info.language" required class="w-full" placeholder="e.g: Монгол">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :disabled="info.disableLevel"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          
          <div class="intro-y col-span-3 sm:col-span-3">
            <div class="mb-2">Түвшин</div>
            <el-select v-model="info.level" required class="w-full" placeholder="e.g: Үндсэн байрлал">
              <div v-for="(item, index) in lessonGroup.list" :key="index" class="flex">
                <el-option
                :label="item.label"
                :disabled="info.disableLevel"
                :value="item.value"
                :style="{'width': '90%'}">
              </el-option>
              <div align="right" width="10%" :style="{'margin': 'auto'}"><Trash2Icon class="w-4 h-4 text-theme-6" @click="DeleteGroup(index)"/></div>
              </div>
            </el-select>
          </div>

          <div class="intro-y col-span-3 sm:col-span-3">
            <div class="mb-2">Хичээлийн нэр</div>
            <el-input v-model="lessonname" required class="w-full" placeholder="e.g: Р & Ө үсэгнүүд"></el-input>
          </div>

          <div class="intro-y col-span-2 sm:col-span-2">
            <div class="mb-2">Level</div>
            <el-input type="number" v-model="level" required class="w-full" placeholder="e.g: Р & Ө үсэгнүүд"></el-input>
          </div>

          <div class="intro-y col-span-12 sm:col-span-12" :hidden="info.dateDisabled">

            <div class="box" :style="{'height': '150px', 'padding-top': '50px'}">
              <input type="file" id="textImage" accept="image/*" hidden @change="onProfileImageSelected" />
              <button type="button" @click="imageTrigger" class="button mr-2 mb-2 flex border text-gray-700 dark:border-dark-5 dark:text-gray-300 whitespace-no-wrap" :style="{'margin': 'auto'}">
                  <UploadIcon class="w-5 h-5 mr-2" /> Зураг сонгох
              </button>
              <div align="center" class="mt-1">{{file.name}}</div>
            </div>

          </div>

          <div class="intro-y col-span-12 sm:col-span-12" :hidden="!info.dateDisabled">
            <div class="box" :style="{'height': '150px', 'padding-top': '50px'}">
                <div class="intro-y col-span-3 sm:col-span-3">
                <div class="mb-2">Hold хийнгээ бичих гараа сонгоно уу</div>
                <el-select v-model="info.hand" required class="w-full" placeholder="e.g: Үндсэн байрлал">
                <el-option
                    v-for="item in options3"
                    :key="item.value"
                    :label="item.label"
                    :disabled="info.disableLevel"
                    :value="item.value">
                </el-option>
                </el-select>
            </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-12">
            <div class="mb-2">Текст</div>
            <textarea
              type="text"
              rows="3"
              class="input w-full border flex-1"
              required
              placeholder="e.g: Hello ..."
              v-model="info.text"
              :maxlength="maxlength"
            >
            </textarea>
          </div>
          
          <div
            class="intro-y col-span-12 flex items-center justify-center sm:justify-end"
          >
            <button
              class="button w-24 justify-center block bg-theme-1 text-white ml-2"
              type="submit"
              :disabled="isDisabled"
            >
              Хадгалах
            </button>
          </div>
        </div>
      </form>
      </div>
    </div>



    <div id="new_group_modal" ref="finish_modal" class="modal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
     <div class="modal__content">
         <div class="p-5 text-center">
             <input type="text" v-model="lessonGroup.newName" maxlength="30" class="input w-full border bg-gray-100 mt-2" placeholder="Бүртгэх групп хичээлийн нэрийг оруулна уу">
             <input type="text" v-model="lessonGroup.wpm" maxlength="30" class="input w-full border bg-gray-100 mt-2" placeholder="Групп хичээлийн босго wpm хурдыг оруулна уу">
         </div>
         <div class="px-5 pb-8 text-right">
            <button type="button" data-dismiss="modal" class="button w-24 bg-theme-7 text-white mr-1"> Болих </button>
            <button @click="this.createGroup" type="button" data-dismiss="modal" class="button w-24 bg-theme-1 text-white"> Хадгалах </button> 
        </div>
     </div>
    </div> 

  </div>
</template>
<script>
import vue2Dropzone from "vue2-dropzone";
export default {
  components: {
    VueDropzone: vue2Dropzone
  },
  data() {
    return {
      lessonname: '',
      level: 0,
      maxlength: 255,
      dateRange: '',
      file: {
        name: '',
        selectedFile: ''
      },
      info: {
        text: '',
        level: 'Түвшингээ сонгоно уу',
        language: 'Монгол',
        type: 'intro',
        hand: 'right',
        disableLevel : false,
        dateDisabled: false
      },
      isDisabled: false,
      raceName: '',
      raceWPM: 0,
      options: [{
          value: 'intro',
          label: 'intro'
        }, {
          value: 'boxed',
          label: 'boxed'
        }, {
          value: 'review',
          label: 'review'
        },{
            value: 'hold',
            label: 'hold'
        },{
          value: 'start',
          label: 'start'
        }],
        options2: [{
          value: 'Монгол',
          label: 'Монгол'
        }, {
          value: 'English',
          label: 'English'
        }],
        options3: [{
          value: 'right',
          label: 'right'
        }, {
          value: 'left',
          label: 'left'
        }],
        lessonGroup: {
            list: [],
            newName: '',
            newWpm: 0
        }
    }
  },
  mounted() {
    this.getGroups();
  },
  methods: {
    DeleteGroup(index) {
      var arr = this.lessonGroup.list[index];
      var rts = this;
      this.$msg({
          title: 'Итгэлтэй байна уу?',
          message:'Энэ түвшинтэй хамааралтай бүх хичээл устах болно.',
          showCancelButton: true,
          type: 'warning',
          center: true,
          confirmButtonText: 'Тийм',
          cancelButtonText: 'Болих',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = 'Түр хүлээнэ үү...';
                const token = localStorage.getItem('token');
                this.$axios({
                    method: 'post',
                    url: this.$appUrl+'/api/admin/delete-lesson-group',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    data: {
                        index: arr.value
                    }
                }).then(function(data) {
                    rts.lessonGroup.list.splice(index, 1);
                    rts.info.level = 'Түвшингээ сонгоно уу';
                    instance.confirmButtonLoading = false;
                    rts.$message({
                        showClose: true,
                        type: 'success',
                        message: 'Амжилттай устгагдлаа'
                    });
                    done();
                }).catch(error => {
                    instance.confirmButtonLoading = false;
                });
            } else {
              done();
            }
          }
        });
      
    },
    showNewLessonGropu() {
        cash('#new_group_modal').modal("show");
    },
    createGroup() {
        let rts = this;
        const token = localStorage.getItem('token');
        this.$axios({
            method: 'POST',
            url: this.$appUrl+'/api/admin/create-lesson-group', 
            headers: {
                'Authorization': `Bearer ${token}`
                },
            data: {
                name: this.lessonGroup.newName,
                wpm: this.lessonGroup.wpm
            }    
        }).then(function(data) {
            if(data.data.result == 'success') {
                rts.lessonGroup.list.push({value: data.data.data[0].id, label: data.data.data[0].groupName});
                rts.$message({
                    showClose: true,
                    type: 'success',
                    message: 'Амжилттай бүртгэгдлээ'
                });
            } else {
                rts.$message({
                    showClose: true,
                    type: 'error',
                    message: data.data.msg
                });
            }
        });
    },
    getGroups() {
        // get-all-lesson-group
        let rts = this;
        const token = localStorage.getItem('token');
        this.$axios({
            method: 'POST',
            url: this.$appUrl+'/api/admin/get-all-lesson-group', 
            headers: {
                'Authorization': `Bearer ${token}`
                }  
        }).then(function(data) {
            // rts.lessonGroup.list = data.data.data;
            var dt = data.data.data;
            dt.forEach(element => {
              rts.lessonGroup.list.push({value: element.id, label: element.groupName});
            });
        });
    },
    checkType() {
        if(this.info.type == 'hold') {
            this.info.dateDisabled = true;
        } else {
             this.info.dateDisabled = false;
        }
    },
    onProfileImageSelected (event) {
      const file = event.target.files[0];
      this.file.selectedFile = file;
      this.file.name = file.name;
    },
    imageTrigger() {
      let el = document.getElementById("textImage");
      el.click();
    },
    async firstImage() {
      const token = localStorage.getItem('token');
      const fd = new FormData();
      fd.append('photo', this.file.selectedFile, this.file.name);

      let img = this.$axios.post(this.$appUrl+'/api/admin/add-lesson-image', 
        fd,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        ).then(function(data) {
          return data.data.img;
        });
      return await img;
    },
    async submit() {
        var type = this.info.type;
        var level = this.info.level;

        if(level != 'Түвшингээ сонгоно уу'){
            if(type != 'hold') {
                if (this.file.selectedFile == '') {
                    alert('Зургаа сонгоно уу');
                } else {
                    let img = await this.firstImage();
                    let rts = this;
                    const token = localStorage.getItem('token');
                    this.$axios({
                        method: 'POST',
                        url: this.$appUrl+'/api/admin/add-new-lesson', 
                        headers: {
                            'Authorization': `Bearer ${token}`
                        },
                        data: {
                            name: this.lessonname,
                            type,
                            level: this.level,
                            text: this.info.text,
                            groupId: level,
                            image: img,
                            hold: this.info.hand
                        }
                    }).then(function(data) {
                        rts.$message({
                            showClose: true,
                            type: 'success',
                            message: 'Амжилттай бүртгэгдлээ'
                        });
                        rts.$router.push('/lesson-list');
                    });
                }
            } else {
                    let rts = this;
                    const token = localStorage.getItem('token');
                    this.$axios({
                        method: 'POST',
                        url: this.$appUrl+'/api/admin/add-new-lesson', 
                        headers: {
                            'Authorization': `Bearer ${token}`
                        },
                        data: {
                            name: this.lessonname,
                            type,
                            level: this.level,
                            text: this.info.text,
                            groupId: level,
                            image: null,
                            hold: this.info.hand
                        }
                    }).then(function(data) {
                        rts.$message({
                            showClose: true,
                            type: 'success',
                            message: 'Амжилттай бүртгэгдлээ'
                        });
                        rts.$router.push('/lesson-list');
                    });
            }
        }
    }
  }
}
</script>