<style scoped>
.wrongLine{
  text-decoration: line-through;
}
</style>

<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">


    <!-- Begin input -->
        <div class="col-span-12 lg:col-span-9 mt-8">
        <div class="intro-y block sm:flex items-center h-10">
          <h2 class="text-lg font-medium truncate mr-5">
            Дунд шат
          </h2>
          
        <div class="ml-auto dropdown inline-block" data-placement="bottom-end"> <button class="dropdown-toggle button w-16 mr-1 mb-2 inline-block bg-theme-1 text-white"> {{this.status.lang}} </button>
            <div class="dropdown-box w-40">
                <div class="dropdown-box__content box dark:bg-dark-1 p-2" align="center"> 
                  <a href="javascript:;" class="block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="changeLang">English</a> 
                  <a href="javascript:;" class="block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="changeLang">Монгол</a> 
                </div>
            </div>
        </div>
        
          
        </div>
        <div class="intro-y box p-5 mt-12 sm:mt-5">
          <div class="flex flex-col xl:flex-row xl:items-center">
            <div class="flex" @click="setFocus" :style="{'background-image' : `url(${require(`@/assets/images/menu/huruu.png`)})`, 'width': '100%', 'height': '350px', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': '700px 350px', 'cursor': 'auto'}" ref="fingerBack">
                <div style="width:100%; height: 100px; margin: auto; display: flex; flex-wrap: nowrap;">
                    <div style="width: 50%; text-align: right; line-height: 100px; font-size: 50px; text-align: right; white-space: nowrap; overflow: hidden;">
                        <span class="typed-text-content" ref="typedTextContent" v-html="build"></span>
                        <div style="display: inline-block; text-align: center; line-height: 100px; font-size: 50px; outline-width: 0; min-width: 10px; color: #6f8fc9; " @keypress.enter.prevent :contenteditable="this.status.contentEdit" @input="this.type" id="testInputDiv" ref="testInputDiv"></div>
                    </div>
                    
                    <div style="width: 50%; line-height: 100px; font-size: 50px; text-align: left; overflow: hidden; white-space: nowrap;" class="main-text-content" ref="mainTextContent">
                      <span style="margin-right: 15px;">{{this.spans.span1}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span2}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span3}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span4}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span5}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span6}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span7}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span8}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span9}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span10}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span11}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span12}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span13}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span14}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span15}}</span>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-12 lg:col-span-3 mt-8">
        <div class="intro-y block sm:flex items-center h-10">
          <h2 class="text-lg font-medium truncate mr-5">
            
          </h2>
          
        </div>
        <div class="col-span-12 sm:col-span-12 lg:col-span-12">
            <div class="intro-y box p-5 mt-5" style="height: 390px;">
            <ReportDonutChart :height="210" class="mt-3" style="margin-top: 70px;" :now="this.time.time_passed" :left="this.time.time_left" />
            <div
              class="font-medium absolute w-full h-full flex items-center justify-center top-0 left-0"
              style="font-size: 60px;"
            >
                  {{this.time.time_left}}
            </div>

            <div class="mt-3">
              <div class="flex items-center">
                <div class="w-2 h-2 bg-theme-9 rounded-full mr-3"></div>
                <span class="truncate">Бичсэн үсгийн тоо</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">{{ this.counter.characters }}</span>
              </div>

              <div class="flex items-center">
                <div class="w-2 h-2 bg-theme-6 rounded-full mr-3"></div>
                <span class="truncate">Алдаатай бичсэн үгийн тоо</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">{{ this.counter.errors }}</span>
              </div>

              <div class="flex items-center">
                <div class="w-2 h-2 bg-theme-7 rounded-full mr-3"></div>
                <span class="truncate">Чанарын үзүүлэлт</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">{{ this.counter.accuracy }}%</span>
              </div>

            </div>
  
            </div>
        </div>
      </div>


      <div class="col-span-12 grid grid-cols-12 gap-6 mt-8">
        <!-- <div class="col-span-12 sm:col-span-6 xxl:col-span-3 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex items-center">
              <div class="w-2/4 flex-none">
                <div class="text-lg font-medium truncate">Чанар</div>
                <div class="text-gray-600 mt-1">Дундаж үзүүлэлт</div>
              </div>
              <div class="flex-none ml-auto relative">
                <ReportDonutChart1 :width="90" :height="90" :percent="info.accuracy"/>
                <div
                  class="font-medium absolute w-full h-full flex items-center justify-center top-0 left-0"
                >
                  {{info.accuracy}}%
                </div>
              </div>
            </div>
          </div>
        </div> -->


        <div class="col-span-12 sm:col-span-6 xxl:col-span-3 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex items-center absolute relative h-full justify-center top-0 left-0">
              <div class="w-2/4 flex-none">
                <div class="text-lg font-medium truncate">Чанар</div>
                <div class="text-gray-600 mt-1">Дундаж үзүүлэлт</div>
              </div>
              <div class="flex-none ml-auto flex">
                <div class="report-box zoom-in">
                  <div class="box p-5" :style="{'padding-left': '40px', 'padding-right': '40px'}">
                    <div class="flex">
                      <div class="ml-auto">
                        <Tippy
                          tag="div"
                          class="report-box__indicator bg-theme-9 cursor-pointer"
                          content="33% Higher than last month"
                        >
                          
                        </Tippy>
                      </div>
                    </div>
                    <div class="flex-none ml-auto relative">
                      <ReportDonutChart1 :width="40" :height="80" :percent="info.accuracy"/>
                      <div
                        class="font-medium absolute w-full h-full flex items-center justify-center top-0 left-0"
                      >
                        {{info.accuracy}}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="col-span-12 sm:col-span-6 xxl:col-span-3 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex items-center absolute relative h-full justify-center top-0 left-0">
              <div class="w-2/4 flex-none">
                <div class="text-lg font-medium truncate"><span class="text-gray-600">Дундаж</span> хурд</div>
              </div>
              <div class="flex-none ml-auto flex">
                <div class="report-box zoom-in">
                  <div class="box p-5" :style="{'padding-left': '40px', 'padding-right': '40px'}">
                    <div class="flex">
                      <div class="ml-auto">
                        <Tippy
                          tag="div"
                          class="report-box__indicator bg-theme-9 cursor-pointer"
                          content="33% Higher than last month"
                        >
                          
                        </Tippy>
                      </div>
                    </div>
                    <div class="text-3xl font-bold leading-8 mt-6">{{info.wpm}}</div>
                    <div class="text-base text-gray-600 mt-1">WPM</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6 xxl:col-span-3 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex items-center absolute relative h-full justify-center top-0 left-0">
              <div class="w-2/4 flex-none">
                <div class="text-lg font-medium truncate"><span class="text-gray-600">Дээд</span> хурд</div>
              </div>
              <div class="flex-none ml-auto flex">
                <div class="report-box zoom-in">
                  <div class="box p-5" :style="{'padding-left': '40px', 'padding-right': '40px'}">
                    <div class="flex">
                      <div class="ml-auto">
                        <Tippy
                          tag="div"
                          class="report-box__indicator bg-theme-9 cursor-pointer"
                          content="33% Higher than last month"
                        >
                          
                        </Tippy>
                      </div>
                    </div>
                    <div class="text-3xl font-bold leading-8 mt-6">{{info.record}}</div>
                    <div class="text-base text-gray-600 mt-1">WPM</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6 xxl:col-span-3 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex items-center absolute relative h-full justify-center top-0 left-0">
              <div class="w-2/4 flex-none" align="center" :style="{'padding-left': '100px'}">
                <img :src="`${$appUrl}/images/rank/${info.rank_img}`" :style="{'width': 'auto', 'height': '70px'}"/>
                <div class="text-lg font-medium truncate"><span class="text-gray-600">{{info.rank_name}}</span></div>
              </div>
              <div class="flex-none ml-auto flex">
                <div class="report-box zoom-in">
                  <div class="box p-5" :style="{'padding-left': '40px', 'padding-right': '40px'}">
                    <div class="flex">
                      <div class="ml-auto">
                        <Tippy
                          tag="div"
                          class="report-box__indicator bg-theme-9 cursor-pointer"
                          content="33% Higher than last month"
                        >
                          
                        </Tippy>
                      </div>
                    </div>
                    <div class="text-3xl font-bold leading-8 mt-6">{{info.point}}</div>
                    <div class="text-base text-gray-600 mt-1">points</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-12 lg:col-span-6 mt-8">
        <div class="intro-y block sm:flex items-center h-10">
          <h2 class="text-lg font-medium truncate mr-5">
            Sales Report
          </h2>
          <div
            class="sm:ml-auto mt-3 sm:mt-0 relative text-gray-700 dark:text-gray-300"
          >
            <CalendarIcon
              class="w-4 h-4 z-10 absolute my-auto inset-y-0 ml-3 left-0"
            />
            <LitePicker
              v-model="salesReportFilter"
              :options="{
                autoApply: false,
                singleMode: false,
                numberOfColumns: 2,
                numberOfMonths: 2,
                showWeekNumbers: true,
                dropdowns: {
                  minYear: 1990,
                  maxYear: null,
                  months: true,
                  years: true
                }
              }"
              class="input w-full sm:w-56 box pl-10"
            />
          </div>
        </div>
        <div class="intro-y box p-5 mt-12 sm:mt-5">
          <div class="flex flex-col xl:flex-row xl:items-center">
            <div class="flex">
              <div>
                <div
                  class="text-theme-20 dark:text-gray-300 text-lg xl:text-xl font-bold"
                >
                  $15,000
                </div>
                <div class="text-gray-600 dark:text-gray-600">This Month</div>
              </div>
              <div
                class="w-px h-12 border border-r border-dashed border-gray-300 dark:border-dark-5 mx-4 xl:mx-6"
              ></div>
              <div>
                <div
                  class="text-gray-600 dark:text-gray-600 text-lg xl:text-xl font-medium"
                >
                  $10,000
                </div>
                <div class="text-gray-600 dark:text-gray-600">Last Month</div>
              </div>
            </div>
            <div class="dropdown xl:ml-auto mt-5 xl:mt-0">
              <button
                class="dropdown-toggle button font-normal border dark:border-dark-5 text-white dark:text-gray-300 relative flex items-center text-gray-700"
              >
                Filter by Category
                <ChevronDownIcon class="w-4 h-4 ml-2" />
              </button>
              <div class="dropdown-box w-40">
                <div
                  class="dropdown-box__content box dark:bg-dark-1 p-2 overflow-y-auto h-32"
                >
                  <a
                    href=""
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >PC & Laptop</a
                  >
                  <a
                    href=""
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >Smartphone</a
                  >
                  <a
                    href=""
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >Electronic</a
                  >
                  <a
                    href=""
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >Photography</a
                  >
                  <a
                    href=""
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >Sport</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="report-chart">
            <ReportLineChart :height="273" class="mt-6" />
          </div>
        </div>
      </div>

      <div class="col-span-12 xl:col-span-3 mt-6">
        <div class="intro-y flex items-center h-10">
          <h2 class="text-lg font-medium truncate mr-5">Top 10</h2>
        </div>
        <div class="mt-5">
        <div style="overflow-x: auto; max-height: 335px; padding: 10px;">
          <div
            v-for="(users, index) in this.tops.alltime"
            :key="index"
            class="intro-y"
          >
            <div class="box px-4 py-4 mb-3 flex items-center zoom-in">
              <div
                class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden"
              >
                <img
                  :alt="`${users.name} image`"
                  :src="`${$appUrl}/images/user/${users.image}`"
                />
              </div>
              <div class="ml-4 mr-auto">
                <div class="font-medium">{{ users.name }}</div>
                <div class="text-gray-600 text-xs">
                  #{{ users.id }}
                </div>
              </div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium"
              >
                {{users.avg_wpm}} WPM
              </div>
            </div>
          </div>
          </div>
          <a
            href=""
            class="intro-y w-full block text-center rounded-md py-4 border border-dotted border-theme-15 dark:border-dark-5 text-theme-16 dark:text-gray-600"
            >Бүгдийг харах</a
          >
        </div>
      </div>


      <div class="col-span-12 xl:col-span-3 mt-6">
        <div class="intro-y flex items-center h-10">
          <h2 class="text-lg font-medium truncate mr-5">Top 10 (энэ сар)</h2>
        </div>
        <div class="mt-5">
        <div style="overflow-x: auto; max-height: 335px; padding: 10px;">
          <div
            v-for="(faker, fakerKey) in $_.take($f(), 4)"
            :key="fakerKey"
            class="intro-y"
          >
            <div class="box px-4 py-4 mb-3 flex items-center zoom-in">
              <div
                class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden"
              >
                <img
                  alt="Midone Tailwind HTML Admin Template"
                  :src="require(`@/assets/images/${faker.photos[0]}`)"
                />
              </div>
              <div class="ml-4 mr-auto">
                <div class="font-medium">{{ faker.users[0].name }}</div>
                <div class="text-gray-600 text-xs">
                  {{ faker.dates[0] }}
                </div>
              </div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium"
              >
                137 Sales
              </div>
            </div>
          </div>
          </div>
          <a
            href=""
            class="intro-y w-full block text-center rounded-md py-4 border border-dotted border-theme-15 dark:border-dark-5 text-theme-16 dark:text-gray-600"
            >View More</a
          >
        </div>
      </div>

    </div>

    <div id="finish_modal" ref="finish_modal" class="modal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false" @click.self="hiddenResultModal">
     <div class="modal__content">
         <div class="p-5 text-center">
             <WatchIcon class="w-16 h-16 text-theme-7 mx-auto mt-3" />
             <div class="text-3xl mt-5">Цаг дууслаа!</div>
             <div class="grid grid-cols-12">
               <div class="col-span-12 xl:col-span-12 mt-6">
                <div class="text-gray-600 mt-2"> Та <span style="font-weight: 700;">{{parseInt((this.counter.characters / 5) / ((60 - this.time.time_left) / 60))}} WPM ({{this.counter.characters}} CPM)</span> бичсэн байна. Таны чанарын үзүүлэлт <span style="font-weight: 700;">{{ this.counter.accuracy }}%</span> байна. Амжилт хүсье!.</div>
               </div>
             </div>
         </div>
         <div class="px-5 pb-8 text-center"><button type="button" data-dismiss="modal" @click="hiddenResultModal" class="button w-24 bg-theme-1 text-white"> За </button> </div>
     </div>
    </div>

  </div>
</template>

<script>
import ReportLineChart from "@/components/ReportLineChart";
import ReportDonutChart from "@/components/ReportDonutChart";
import ReportPieChart from "@/components/ReportPieChart";
import ReportMap from "@/components/ReportMap";
import ReportDonutChart1 from "@/components/ReportDonutChart1";
import SimpleLineChart1 from "@/components/SimpleLineChart1";
import VueSlickCarousel from "vue-slick-carousel";

let timer = null;

export default {
  components: {
    ReportLineChart,
    ReportDonutChart,
    ReportPieChart,
    ReportMap,
    ReportDonutChart1,
    SimpleLineChart1,
    VueSlickCarousel
  },
  data() {
    return {
      salesReportFilter: "",
      texts: {
          original: '',
          typed : '',
          now: '',
          splitted: '',
          splittedOneWord: [],
          currentWord: ''
      },
      info: {
        accuracy: 0,
        wpm: 0,
        record: 0,
        rank_name: '',
        rank_img: '',
        point: 0
      },
      counter: {
          space: 0,
          typed: 0,
          errors: 0,
          accuracy: 0,
          characters: 0
      },
      status: {
        contentEdit: true,
        lang: 'Мон',
        finger : true
      },
      tops: {
        alltime: [],
        monthly: []
      },
      time: {
          time_left: 60,
          time_passed: 0,
          start: false
      },
      spans: {
        span1: '',
        span2: '',
        span3: '',
        span4: '',
        span5: '',
        span6: '',
        span7: '',
        span8: '',
        span9: '',
        span10: '',
        span11: '',
        span12: '',
        span13: '',
        span14: '',
        span15: ''
      }
    };
  },
  computed: {
    build: function() {
      return this.texts.typed;
    }
  },
  mounted(){
    this.eng_finger();
    document.getElementById("testInputDiv").focus();
    this.getText();
    this.getTops();
    this.getInfo();
  },
  methods: {
    changeLang(event) {
      if(event.srcElement.innerHTML === 'Монгол') {
        this.status.lang = 'Мон';
      } else {
        this.status.lang = 'Eng';
      }
      clearInterval(timer);
      this.getText();      
    },
    getTops() {
      var rts = this;
      this.$axios.get(this.$appUrl+'/api/users/tops')
      .then(function(data){
        if(data.data.result === 'success') {
          rts.tops.alltime = data.data.data.top_all_time;
        }
      });
    },
    getInfo() {
      const token = localStorage.getItem('token');
      var rts = this;
      if(token) {
        this.$axios.get(this.$appUrl+'/api/users/current', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(function(data) {
            if(data.data.status === 400) {

              localStorage.removeItem('token');
              localStorage.removeItem('user');
              localStorage.removeItem('active');
              rts.$router.push('/login');

            } else if(data.data.status === 401) {
                rts.$checkAuth.check_auth(rts);
                rts.getInfo();
            } else if(data.data.status === 200) {
                rts.info.accuracy = data.data.data.info.avg_percent;
                rts.info.wpm = data.data.data.info.avg_wpm;
                rts.info.record = data.data.data.info.record_wpm;
                rts.info.rank_name = data.data.data.info.name;
                rts.info.rank_img = data.data.data.info.image;
                rts.info.point = data.data.data.info.point;
            }
        });
      }
    },
    prevImportantNotes() {
      this.$refs["important-notes"].prev();
    },
    getText() {

      this.counter.typed = 0;
      this.counter.space = 0;
      this.counter.errors = 0;
      this.counter.accuracy = 0;
      this.counter.characters = 0;
      this.status.contentEdit = true;
      this.time.time_left = 60;
      this.time.time_passed = 0;
      this.time.start = false;
      this.texts.typed = '';
      this.$refs.testInputDiv.innerHTML = '';


      var rts = this;
      this.$axios.get(this.$appUrl+'/api/text/begin', {
          params: {
            language: this.status.lang
          }
      })
      .then(function(data){
        if(data.data.result === 'success') {
          rts.texts.original = data.data.data;
          rts.makeSpanText();
        }
      });
    },
    nextImportantNotes() {
      this.$refs["important-notes"].next();
    },
    makeSpanText() {
        var current_content = '';
        this.texts.splitted = this.texts.original.split(' ');
      this.giveSpanText();

    },
    setFocus() {
      this.$refs.testInputDiv.focus();
    },
    hiddenResultModal() {
      document.getElementById("testInputDiv").focus();
      this.getText();
    },
    giveSpanText(){
      this.spans.span1 = '';
      this.spans.span2 = '';
      this.spans.span3 = '';
      this.spans.span4 = '';
      this.spans.span5 = '';
      this.spans.span6 = '';
      this.spans.span7 = '';
      this.spans.span8 = '';
      this.spans.span9 = '';
      this.spans.span10 = '';
      this.spans.span11 = '';
      this.spans.span12 = '';
      this.spans.span13 = '';
      this.spans.span14 = '';
      this.spans.span15 = '';
      

      if(this.counter.space < this.texts.splitted.length) {
        for(let i=this.counter.space; i < this.texts.splitted.length; i++){
          switch(i) {
            case this.counter.space : this.spans.span1 = this.texts.splitted[i]; break;
            case this.counter.space + 1 : this.spans.span2 = this.texts.splitted[i]; break;
            case this.counter.space + 2: this.spans.span3 = this.texts.splitted[i]; break;
            case this.counter.space + 3: this.spans.span4 = this.texts.splitted[i]; break;
            case this.counter.space + 4: this.spans.span5 = this.texts.splitted[i]; break;
            case this.counter.space + 5: this.spans.span6 = this.texts.splitted[i]; break;
            case this.counter.space + 6: this.spans.span7 = this.texts.splitted[i]; break;
            case this.counter.space + 7: this.spans.span8 = this.texts.splitted[i]; break;
            case this.counter.space + 8: this.spans.span9 = this.texts.splitted[i]; break;
            case this.counter.space + 9: this.spans.span10 = this.texts.splitted[i]; break;
            case this.counter.space + 10: this.spans.span11 = this.texts.splitted[i]; break;
            case this.counter.space + 11: this.spans.span12 = this.texts.splitted[i]; break;
            case this.counter.space + 12: this.spans.span13 = this.texts.splitted[i]; break;
            case this.counter.space + 13: this.spans.span14 = this.texts.splitted[i]; break;
            case this.counter.space + 14: this.spans.span15 = this.texts.splitted[i]; break;
            default: break;
          }
        }
        this.splitWord();
      } else {
        this.finishGame();
      }
    },
    splitWord() {
      this.texts.splittedOneWord = this.texts.splitted[this.counter.space].split('');
      this.texts.currentWord =  this.texts.splittedOneWord[0];

      if(this.status.lang === 'Eng') {
        this.eng_finger();
      } else {
        this.mon_finger();
      }
    },
    changeFinger() {
      if( this.status.finger == true) {
        this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/huruu.png`)})`;
        this.status.finger = false;
      } else {
        //this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/huruu_zuun_doloowor.png`)})`;
        this.status.finger = true;
      }
      
    },
    type(){
      // this.changeFinger();
      
      if(this.time.start == false) {
        this.startGame();
      }
      var text = this.texts.splitted[this.counter.space];
      var input = this.$refs.testInputDiv.innerHTML.replace('&nbsp;','');
      
      var space = this.$refs.testInputDiv.innerHTML.substr(this.$refs.testInputDiv.innerHTML.length - 1, this.$refs.testInputDiv.innerHTML.length);

      var input_array = input.split('');
      var permission = true;
      

      for(let i=-1; i < input_array.length; i++) {
        if(input_array[i] != this.texts.splittedOneWord[i]) {
          permission = false; break;
        }
        this.texts.currentWord = this.texts.splittedOneWord[i + 1];
      }

      if(this.status.lang === 'Eng') {
        this.eng_finger();
      } else {
        this.mon_finger();
      }
      

        if(permission) {
          
            if(space === ';'){

              if(input != '') {
                if(text === input.replace('&nbsp;','')) {
                  this.texts.typed = this.texts.typed +  '<span style="margin-right: 15px; color: #a3a0a0;">'+ input.replace('&nbsp;','') + '</span>';
                  this.counter.characters += text.length;
                  this.counter.typed++;
                } else {
                  this.texts.typed = this.texts.typed +  '<span style="text-decoration: line-through; margin-right: 15px; color: #a3a0a0;">'+ input.replace('&nbsp;','') + '</span>';
                  this.counter.errors++;
                }
                
                this.$refs.testInputDiv.innerHTML = '';
                this.counter.space++;

                this.counter.accuracy = parseInt(this.counter.typed / ( this.counter.typed + this.counter.errors )  * 100 );

                this.giveSpanText();
              } else {
                this.$refs.testInputDiv.innerHTML = '';
              } 
            }
            else {
              this.$refs.testInputDiv.classList.remove('wrongLine');
              this.spans.span1 = text.substr(input.length, text.length);
            }

        } else {
            if(space === ';') {

                if(input != '') {
                  this.texts.typed = this.texts.typed +  '<span style="text-decoration: line-through; margin-right: 15px; color: #a3a0a0;">'+ input.replace('&nbsp;','') + '</span>';
                  this.counter.errors++;
                  
                  this.$refs.testInputDiv.innerHTML = '';
                  this.counter.space++;
                  this.counter.accuracy = parseInt(this.counter.typed / ( this.counter.typed + this.counter.errors )  * 100 );
                  this.giveSpanText();
                } else {
                  this.$refs.testInputDiv.innerHTML = '';
                }
            } else {
              this.$refs.testInputDiv.classList.add('wrongLine');
            }
          
        }
    },

    eng_finger() {
      
      if(this.texts.currentWord == undefined) {
        return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/huruu_space.png`)})`;
      }

      var left_4 = ['Q','q','Z','z','A','a','1','!','`','~'];
      var left_3 = ['W','w','S','s','X','x','2','@'];
      var left_2 = ['E','e','D','d','C','c','3','#'];
      var left_1 = ['R','r','F','f','V','v','T','t','G','g','B','b','4','$','5','%'];
      var right_1 = ['Y','y','H','h','N','n','6','^','7','&','U','u','J','j','M','m'];
      var right_2 = ['8','*','I','i','K','k',',','<'];
      var right_3 = ['(','9','O','o','L','l','>','.'];
      var right_4 = [')','0','P','p',':',';','/','?','-','_','{','[','"','\'','+','=','}',']','|','\\'];

      for (let i = 0; i < left_4.length; i++) {
        if (left_4[i] == this.texts.currentWord) {
          return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/left_4.png`)})`;
        }
      }

      for (let i = 0; i < left_3.length; i++) {
        if (left_3[i] == this.texts.currentWord) {
          return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/left_3.png`)})`;
        }
      }

      for (let i = 0; i < left_2.length; i++) {
        if (left_2[i] == this.texts.currentWord) {
          return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/left_2.png`)})`;
        }
      }

      for (let i = 0; i < left_1.length; i++) {
        if (left_1[i] == this.texts.currentWord) {
          return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/left_1.png`)})`;
        }
      }

      for (let i = 0; i < right_1.length; i++) {
        if (right_1[i] == this.texts.currentWord) {
          return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/right_1.png`)})`;
        }
      }

      for (let i = 0; i < right_2.length; i++) {
        if (right_2[i] == this.texts.currentWord) {
          return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/right_2.png`)})`;
        }
      }

      for (let i = 0; i < right_3.length; i++) {
        if (right_3[i] == this.texts.currentWord) {
          return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/right_3.png`)})`;
        }
      }

      for (let i = 0; i < right_4.length; i++) {
        if (right_4[i] == this.texts.currentWord) {
          return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/right_4.png`)})`;
        }
      }
    },
    mon_finger() {
      
      if(this.texts.currentWord == undefined) {
        return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/huruu_space.png`)})`;
      }
      
      var left_4 = ['=','+','№','1','Ф','ф','Й','й','Я','я'];
      var left_3 = ['2','-','Ц','ц','ы','Ы','ч','Ч'];
      var left_2 = ['3','"','У','у','Б','б','Ё','ё'];
      var left_1 = ['4','5',':','₮','ж','Ж','Ө','ө','С','с','Э','э','А','а','М','м'];
      var right_1 = ['6','.','7','_','Н','н','Г','г','Х','х','Р','р','И','и','т','Т'];
      var right_2 = [',','8','Ш','ш','О','о','Ь','ь'];
      var right_3 = ['9','%','Ү','ү','Л','л','В','в'];
      var right_4 = ['0','?','Е','е','Щ','щ','З','з','К','к','Ъ','ъ','д','Д','П','п','Ю','ю','\\','|','\''];

      for (let i = 0; i < left_4.length; i++) {
        if (left_4[i] == this.texts.currentWord) {
          return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/left_4.png`)})`;
        }
      }

      for (let i = 0; i < left_3.length; i++) {
        if (left_3[i] == this.texts.currentWord) {
          return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/left_3.png`)})`;
        }
      }

      for (let i = 0; i < left_2.length; i++) {
        if (left_2[i] == this.texts.currentWord) {
          return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/left_2.png`)})`;
        }
      }

      for (let i = 0; i < left_1.length; i++) {
        if (left_1[i] == this.texts.currentWord) {
          return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/left_1.png`)})`;
        }
      }

      for (let i = 0; i < right_1.length; i++) {
        if (right_1[i] == this.texts.currentWord) {
          return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/right_1.png`)})`;
        }
      }

      for (let i = 0; i < right_2.length; i++) {
        if (right_2[i] == this.texts.currentWord) {
          return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/right_2.png`)})`;
        }
      }

      for (let i = 0; i < right_3.length; i++) {
        if (right_3[i] == this.texts.currentWord) {
          return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/right_3.png`)})`;
        }
      }

      for (let i = 0; i < right_4.length; i++) {
        if (right_4[i] == this.texts.currentWord) {
          return this.$refs.fingerBack.style.backgroundImage = `url(${require(`@/assets/images/menu/right_4.png`)})`;
        }
      }

    },
    updateTimer() {
      if (this.time.time_left > 0) {
        this.time.time_left--;
        this.time.time_passed++;
      }
      else {
        this.finishGame();
      }
    },
    finishGame() {
      clearInterval(timer);
      this.time.start = false;
      this.status.contentEdit = false;
      
      cash("#finish_modal").modal("show");
      this.upgradeWpm();
    },
    upgradeWpm() {
      var rts = this;
      const token = localStorage.getItem('token');
      if(token) {
        this.$axios.get(this.$appUrl+'/api/wpm/upgrade', {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: {
            wpm: parseInt((this.counter.characters / 5) / ((60 - this.time.time_left) / 60)),
            percent: this.counter.accuracy
          }
        }).then(function(data) {

          if(data.data.status === 400) {

              localStorage.removeItem('token');
              localStorage.removeItem('user');
              localStorage.removeItem('active');
              rts.$router.push('/login');

          } else if(data.data.status === 401) {
              rts.$checkAuth.check_auth(rts);
              rts.upgradeWpm();
          } else if(data.data.status === 200) {
              console.log(data);
          }
        });
      }
    },
    startGame() {
      clearInterval(timer);
      this.time.start = true;
      timer = setInterval(this.updateTimer, 1000);
    }
  }
};
</script>
