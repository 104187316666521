<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
.font-title {
  font-family: 'Exo 2', sans-serif;
}
</style>
<template>
  <div class="grid grid-cols-12 gap-6 font-title">
    <div class="col-span-9 xxl:col-span-9 grid grid-cols-9 gap-6">
      <!-- BEGIN: General Report -->
      <div class="col-span-12 mt-8">
        <div class="intro-y flex items-center h-10">
          <h2 class="text-lg font-medium truncate mr-5">Хэрэглэгчид</h2>
          <a href="" class="ml-auto flex text-theme-1 dark:text-theme-10">
            <RefreshCcwIcon class="w-4 h-4 mr-3" /> Reload Data
          </a>
        </div>
        <div class="grid grid-cols-12 gap-6 mt-5">
          <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <ShoppingCartIcon class="report-box__icon text-theme-10" />
                  <div class="ml-auto">
                    <Tippy
                      tag="div"
                      class="report-box__indicator bg-theme-9 cursor-pointer"
                    >
                      <span class="mr-1">100%</span>
                    </Tippy>
                  </div>
                </div>
                <div class="text-3xl font-bold leading-8 mt-6">{{users.total}}</div>
                <div class="text-base text-gray-600 mt-1">Нийт хэрэглэгчид</div>
              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <CreditCardIcon class="report-box__icon text-theme-11" />
                  <div class="ml-auto">
                    <Tippy
                      tag="div"
                      class="report-box__indicator bg-theme-6 cursor-pointer"
                      v-bind:class="{'bg-theme-9': parseInt((users.paid * 100) / users.total) >= 50}"
                      content="Нийт хэрэглчдээс эзэлж буй хувь"
                    >
                      <span class="mr-1">{{parseInt((users.paid * 100) / users.total)}}%</span>
                    </Tippy>
                  </div>
                </div>
                <div class="text-3xl font-bold leading-8 mt-6">{{users.paid}}</div>
                <div class="text-base text-gray-600 mt-1">Төлбөр төлөгдсөн</div>
              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <MonitorIcon class="report-box__icon text-theme-12" />
                  <div class="ml-auto">
                    <Tippy
                      tag="div"
                      class="report-box__indicator bg-theme-6 cursor-pointer"
                      v-bind:class="{'bg-theme-9': parseInt((users.finish * 100) / users.total) >= 50}"
                      content="Нийт хэрэглчдээс эзэлж буй хувь"
                    >
                      <span class="mr-1">{{parseInt((users.finish * 100) / users.total)}}%</span>
                    </Tippy>
                  </div>
                </div>
                <div class="text-3xl font-bold leading-8 mt-6">{{users.finish}}</div>
                <div class="text-base text-gray-600 mt-1">Хичээл бүрэн дуусгасан</div>
              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <UserIcon class="report-box__icon text-theme-9" />
                  <div class="ml-auto">
                    <Tippy
                      tag="div"
                      class="report-box__indicator bg-theme-9 cursor-pointer"
                      content="Нийт хэрэглчдээс эзэлж буй хувь"
                    >
                      <span class="mr-1">{{parseInt((users.thisMonth.length * 100) / users.total)}}%</span>
                    </Tippy>
                  </div>
                </div>
                <div class="text-3xl font-bold leading-8 mt-6">{{users.thisMonth.length}}</div>
                <div class="text-base text-gray-600 mt-1">Энэ сард бүртгүүлсэн</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: General Report -->
      <!-- BEGIN: Sales Report -->
      <div class="col-span-12 lg:col-span-6 mt-8">
        <div class="intro-y block sm:flex items-center h-10">
          <h2 class="text-lg font-medium truncate mr-5">
            Сүүлд нэмэгдсэн хэрэглэгчид
          </h2>
        </div>
        <div class="intro-y box p-5 mt-12 sm:mt-5">
          <!-- <div class="flex flex-col xl:flex-row xl:items-center">
            <div class="flex">
              <div>
                <div
                  class="text-theme-20 dark:text-gray-300 text-lg xl:text-xl font-bold"
                >
                  $15,000
                </div>
                <div class="text-gray-600 dark:text-gray-600">This Month</div>
              </div>
              <div
                class="w-px h-12 border border-r border-dashed border-gray-300 dark:border-dark-5 mx-4 xl:mx-6"
              ></div>
              <div>
                <div
                  class="text-gray-600 dark:text-gray-600 text-lg xl:text-xl font-medium"
                >
                  $10,000
                </div>
                <div class="text-gray-600 dark:text-gray-600">Last Month</div>
              </div>
            </div>
          </div> -->
          <div class="report-chart">
            <ReportLineChart :height="273" :users="users.counter" class="mt-6" />
          </div>
        </div>
      </div>
      <!-- END: Sales Report -->
      <!-- BEGIN: Weekly Top Seller -->
      <div class="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">
        <div class="intro-y flex items-center h-10">
          <h2 class="text-lg font-medium truncate mr-5">Хэрэглэгчийн төлбөр төлөлт</h2>
          <router-link to="/users" class="ml-auto text-theme-1 dark:text-theme-10 truncate"
            >See all</router-link
          >
        </div>
        <div class="intro-y box p-5 mt-5">
          <ReportPieChart :height="210" :total="users.total - users.paid" :paid="users.paid" class="mt-3" />
          <div class="mt-8">
            <div class="flex items-center">
              <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
              <span class="truncate">Төлбөр төлөгдөөгүй</span>
              <div
                class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
              ></div>
              <span class="font-medium xl:ml-auto">62%</span>
            </div>
            <div class="flex items-center mt-1">
              <div class="w-2 h-2 bg-theme-1 rounded-full mr-3"></div>
              <span class="truncate">Төлбөр төлсөн</span>
              <div
                class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
              ></div>
              <span class="font-medium xl:ml-auto">33%</span>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Weekly Top Seller -->
      <!-- BEGIN: General Report -->
      <div class="col-span-12 grid grid-cols-12 gap-6 mt-8">
        <div class="col-span-12 sm:col-span-6 xxl:col-span-3 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex items-center">
              <div class="w-2/4 flex-none">
                <div class="text-lg font-medium truncate">Target Sales</div>
                <div class="text-gray-600 mt-1">300 Sales</div>
              </div>
              <div class="flex-none ml-auto relative">
                <ReportDonutChart1 :width="90" :height="90" />
                <div
                  class="font-medium absolute w-full h-full flex items-center justify-center top-0 left-0"
                >
                  20%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6 xxl:col-span-3 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex">
              <div class="text-lg font-medium truncate mr-3">Social Media</div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-gray-200 dark:bg-dark-5 text-gray-600 dark:text-gray-300 cursor-pointer ml-auto truncate"
              >
                320 Followers
              </div>
            </div>
            <div class="mt-4">
              <SimpleLineChart1 :height="60" class="-ml-1" />
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6 xxl:col-span-3 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex items-center">
              <div class="w-2/4 flex-none">
                <div class="text-lg font-medium truncate">New Products</div>
                <div class="text-gray-600 mt-1">1450 Products</div>
              </div>
              <div class="flex-none ml-auto relative">
                <ReportDonutChart1 :width="90" :height="90" />
                <div
                  class="font-medium absolute w-full h-full flex items-center justify-center top-0 left-0"
                >
                  45%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6 xxl:col-span-3 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex">
              <div class="text-lg font-medium truncate mr-3">Posted Ads</div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-gray-200 dark:bg-dark-5 text-gray-600 dark:text-gray-300 cursor-pointer ml-auto truncate"
              >
                180 Campaign
              </div>
            </div>
            <div class="mt-4">
              <SimpleLineChart1 :height="60" class="-ml-1" />
            </div>
          </div>
        </div>
      </div>
      <!-- END: General Report -->
    </div>
    <div
      class="col-span-3 xxl:col-span-3 xxl:border-l border-theme-5 -mb-10 pb-10"
    >
      <div class="xxl:pl-12 grid grid-cols-12 gap-6">
        <div
          class="col-span-12 md:col-span-12 xl:col-span-12 xxl:col-span-12 mt-3 xxl:mt-8"
        >
          <div class="intro-x flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Сүүлд нэмэгдсэн хэрэглэгчид</h2>
          </div>
          <div class="mt-5 listScroll" :style="{'overflow-x': 'hidden', 'overflow-y': 'auto', 'max-height': '700px'}">
            <div
              v-for="(user, index) in users.thisMonth"
              :key="index"
              class="intro-x"
            >
              <div class="box px-5 py-3 mb-3 flex items-center zoom-in">
                <div
                  class="w-10 h-10 flex-none image-fit rounded-full overflow-hidden"
                >
                  <img
                    alt="Midone Tailwind HTML Admin Template"
                    :src="user.image"
                  />
                </div>
                <div class="ml-4 mr-auto">
                  <div class="font-medium">
                    {{ user.name}}
                  </div>
                  <div class="text-gray-600 text-xs">
                    #{{ user.id }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Transactions -->
      </div>
    </div>
  </div>
</template>

<script>
import ReportLineChart from "@/components/ReportLineChart";
import ReportDonutChart from "@/components/ReportDonutChart";
import ReportPieChart from "@/components/ReportPieChart";
import ReportMap from "@/components/ReportMap";
import ReportDonutChart1 from "@/components/ReportDonutChart1";
import SimpleLineChart1 from "@/components/SimpleLineChart1";
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: {
    ReportLineChart,
    ReportDonutChart,
    ReportPieChart,
    ReportMap,
    ReportDonutChart1,
    SimpleLineChart1,
    VueSlickCarousel
  },
  data() {
    return {
      salesReportFilter: "",
      users: {
        total: 0,
        paid: 0,
        finish: 0,
        thisMonth: [],
        counter: []
      }
    };
  },
  mounted() {
    this.getInfoUsers();
  },
  methods: {
    prevImportantNotes() {
      this.$refs["important-notes"].prev();
    },
    nextImportantNotes() {
      this.$refs["important-notes"].next();
    },
    getInfoUsers() {
      let rts = this;
        const token = localStorage.getItem('token');
        this.$axios({
            method: 'POST',
            url: this.$appUrl+'/api/admin/admin-dashboard', 
            headers: {
              'Authorization': `Bearer ${token}`
            }   
        }).then(function(data) {
            rts.users.total = data.data.all;
            rts.users.paid = data.data.paid;
            rts.users.thisMonth = data.data.thismonth;
            rts.users.finish = data.data.finishLesson;
            rts.users.counter = data.data.groupUser
        });
    }
  }
};
</script>
