<template>
  <div>
    <!-- <title>{{ $route.meta.title }}</title> -->
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Нууц үг шинэчлэх</h2>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <!-- BEGIN: Profile Menu -->
      <div
        class="col-span-12 lg:col-span-4 xxl:col-span-3 flex lg:block flex-col-reverse"
      >
        <div class="intro-y box mt-5">
          <div class="relative flex items-center p-5">
            <div class="w-12 h-12 image-fit">
              <img
                alt="Midone Tailwind HTML Admin Template"
                class="rounded-full"
                :src="this.user.image"
              />
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium text-base">
                Сайн уу?
              </div>
              <div class="text-gray-600">{{ this.user.name }}</div>
            </div>
          </div>
          <div class="p-5 border-t border-gray-200 dark:border-dark-5">
            <router-link
              class="flex items-center"
              to="/profile"
            >
              <UserIcon class="w-4 h-4 mr-2" /> Хувийн мэдээлэл
            </router-link>

            <router-link class="flex items-center mt-5 text-theme-1 dark:text-theme-10 font-medium" to="/change-password">
              <LockIcon class="w-4 h-4 mr-2" /> Нууц үг шинэчлэх
            </router-link>
          </div>
        </div>
      </div>
      <!-- END: Profile Menu -->
      <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
        <!-- BEGIN: Change Password -->
        <div class="intro-y box lg:mt-5">
          <div
            class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Нууц үг шинэчлэх</h2>
          </div>
          <div class="p-5" >
            <form @submit.prevent="submit">
            <div>
              <label>Хуучин нууц үг</label>
              <input
                type="password"
                class="input w-full border mt-2"
                placeholder="Input text"
                v-model="passwords.old"
                required
                pattern=".{6,}"
              />
            </div>
            <div class="mt-3">
              <label>Шинэ нууц үг</label>
              <input
                type="password"
                class="input w-full border mt-2"
                placeholder="Input text"
                v-model="passwords.new"
                ref="password"
                required
                pattern=".{6,}"
              />
            </div>
            <div class="mt-3">
              <label>Давтан нууц үг</label>
              <input
                type="password"
                class="input w-full border mt-2"
                placeholder="Input text"
                v-model="passwords.confirm"
                required
                pattern=".{6,}"
              />
            </div>
            <button type="submit" class="button bg-theme-1 text-white mt-4" :style="{'width': '120px'}">
              Хадгалах
            </button>
            </form>
          </div>
        </div>
        <!-- END: Change Password -->
      </div>
    </div>
    
  </div>
</template>
<script>

export default {
  mounted(){
    var user = localStorage.getItem('user');
    if(user != null) {
      var user = JSON.parse(localStorage.getItem('user'));
      this.user.name = user.name;
      this.user.image = user.image;
    }
  },
  data() {
    return {
      passwords: {
        old: '',
        new: '',
        confirm: ''
      },
      user: {
        name: '',
        image: ''
      }
    }
  },
  methods: {
    submit() {
      var rts = this;
      if(this.passwords.new !== this.passwords.confirm) {
        rts.$message({
            message: 'Баталгаажуулах нууц үг буруу',
            type: 'warning'
        });
      } else {
        const token = localStorage.getItem('token');
        var rts = this;
        this.$axios({
            method: 'post',
            url: this.$appUrl+'/api/admin/change-password',
            headers: {
              'Authorization': `Bearer ${token}`
            },
            data: {
              password: this.passwords.new,
              oldPassword: this.passwords.old
            }
          }).then(function(data) {
            if(data.data.result == 'success') {
              rts.$message({
                  showClose: true,
                  message: data.data.data,
                  type: 'success'
              });
            } else {
              rts.$message({
                  showClose: true,
                  message: data.data.data,
                  type: 'warning'
              });
            }
          }).catch(error => {
            console.log(error);
          });
      }
    }
  }
}
</script>
