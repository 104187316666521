<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">Текст</h2>
    </div>
    <div class="overflow-x-auto">
      <table class="table mt-5">
         <thead>
             <tr class="bg-gray-700 dark:bg-dark-1 text-white">
                 <th class="whitespace-no-wrap" width="5%">#</th>
                 <th class="whitespace-no-wrap" width="70%">Текст</th>
                 <th class="whitespace-no-wrap" width="10%">Түвшин</th>
                 <th class="whitespace-no-wrap" width="5%">Хэл</th>
                 <th class="whitespace-no-wrap" width="10%"></th>
             </tr>
         </thead>
         <tbody v-if="text.length <= 0">
           <tr>
             <td align="center" colspan="5">
               Илэрцгүй
             </td>
           </tr>
         </tbody>
         <tbody v-else>
             <tr v-for="(rows, index) in text" :key="index">
                 <td class="border-b dark:border-dark-5">{{rows.id}}</td>
                 <td class="border-b dark:border-dark-5"> {{rows.text}} </td>
                 <td class="border-b dark:border-dark-5"> 
                   <span v-if="rows.rank == 1">Анхан</span>
                   <span v-else-if="rows.rank == 2">Дунд</span>
                   <span v-else-if="rows.rank == 3">Ахисан</span>
                 </td>
                 <td class="border-b dark:border-dark-5"> <b>{{rows.lan}}</b> </td>
                 <td class="border-b dark:border-dark-5" align="right"><button @click="onDelete(rows.id)" class="button px-2 mr-1 mb-2 bg-theme-6 text-white"> <span class="w-5 h-5 flex items-center justify-center"> <TrashIcon class="w-4 h-4" /> </span> </button></td>
             </tr>
             <tr>
               <td align="center" colspan="5">
                 <button class="button w-full xl:w-40 text-white bg-theme-7 xl:mr-3 align-top" @click="moreText"> Цааш </button>
               </td>
             </tr>
         </tbody>
     </table>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      text: [],
      count: 1
    }
  },
  mounted() {
    this.getText();
  },
  methods: {
    moreText() {
      this.count += 1;
      this.getText();
    },
    getText() {
      let rts = this;
      const token = localStorage.getItem('token');
      this.$axios({
        method: 'POST',
        url: this.$appUrl+'/api/admin/get-text', 
        headers: {
              'Authorization': `Bearer ${token}`
          },
        data: {
          count: this.count
        }
        }).then(function(data) {
          data.data.data.forEach(element => {
            rts.text.push(element);
          });
          // rts.text.push(data.data.data[1]);
        }).catch(error => {
          console.log(error);
        });
    },
    onDelete(id) {
      let rts = this;
      const h = this.$createElement;
        this.$msg({
          title: 'Текст устгах',
          message:'Та устгахдаа итгэлтэй байна уу?',
          showCancelButton: true,
          confirmButtonText: 'Тийм',
          cancelButtonText: 'Болих',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = 'Түр хүлээнэ үү...';

              const token = localStorage.getItem('token');
              rts.$axios({
                method: 'POST',
                url: this.$appUrl+'/api/admin/delete-text', 
                headers: {
                      'Authorization': `Bearer ${token}`
                  },
                data: {
                  id
                }
              }).then(function(data) {
                instance.confirmButtonLoading = false;
                rts.text.forEach((element, index) => {
            
                  if(element.id == id) {
                    rts.text.splice(index, 1);
                  }
                });
                done();
              }).catch(error => {
                  console.log(error);
              });
              
            } else {
              done();
            }
          }
        });
    }
  }
};
</script>
