import Vue from "vue";
import VueRouter from "vue-router";
import SideMenu from "../layouts/SideMenu";
import SimpleMenu from "../layouts/SimpleMenu";
import TopMenu from "../layouts/TopMenu";
import Dashboard from "../views/Dashboard";
import Inbox from "../views/Inbox";
import FileManager from "../views/FileManager";
import PointOfSale from "../views/PointOfSale";
import Chat from "../views/Chat";
import Post from "../views/Post";
import CrudDataList from "../views/CrudDataList";
import CrudForm from "../views/CrudForm";
import UsersLayout1 from "../views/UsersLayout1";
import UsersLayout2 from "../views/UsersLayout2";
import UsersLayout3 from "../views/UsersLayout3";
import ProfileOverview3 from "../views/ProfileOverview3";
import WizardLayout1 from "../views/WizardLayout1";
import WizardLayout2 from "../views/WizardLayout2";
import WizardLayout3 from "../views/WizardLayout3";
import BlogLayout1 from "../views/BlogLayout1";
import BlogLayout2 from "../views/BlogLayout2";
import BlogLayout3 from "../views/BlogLayout3";
import PricingLayout1 from "../views/PricingLayout1";
import PricingLayout2 from "../views/PricingLayout2";
import InvoiceLayout1 from "../views/InvoiceLayout1";
import InvoiceLayout2 from "../views/InvoiceLayout2";
import FaqLayout1 from "../views/FaqLayout1";
import FaqLayout2 from "../views/FaqLayout2";
import FaqLayout3 from "../views/FaqLayout3";
import Login from "../views/Login";
import Register from "../views/Register";
import ErrorPage from "../views/ErrorPage";
import UpdateProfile from "../views/UpdateProfile";
import ChangePassword from "../views/ChangePassword";
import RegularTable from "../views/RegularTable";
import Tabulator from "../views/Tabulator";
import Accordion from "../views/Accordion";
import Button from "../views/Button";
import Modal from "../views/Modal";
import Alert from "../views/Alert";
import ProgressBar from "../views/ProgressBar";
import Tooltip from "../views/Tooltip";
import Dropdown from "../views/Dropdown";
import Toast from "../views/Toast";
import Typography from "../views/Typography";
import Icon from "../views/Icon";
import LoadingIcon from "../views/LoadingIcon";
import RegularForm from "../views/RegularForm";
import Datepicker from "../views/Datepicker";
import FileUpload from "../views/FileUpload";
import WysiwygEditor from "../views/WysiwygEditor";
import Validation from "../views/Validation";
import Chart from "../views/Chart";
import Slider from "../views/Slider";
import ImageZoom from "../views/ImageZoom";
import Beginner from "../views/Beginner";
import Middle from "../views/Middle";
import RaceText from "../views/RaceText";
import banners from "../views/banners"
import users from "../views/users"
import OnlineRaceText from "../views/OnlineRaceText"
import CouponCodes from "../views/CouponCodes"
import addLesson from "../views/addLesson"
import lessonList from "../views/LessonList"


function AuthCheck(to, from, next) {
  var isAuthenticated = false;
  var token = localStorage.getItem("token");
  if (token) {
    isAuthenticated = true;
  }
  if (isAuthenticated) {
    next();
  } else {
    next("/login");
  }
}

function LoginCheck(to, from, next) {
  var isAuthenticated = true;
  var token = localStorage.getItem("token");
  if (!token) {
    isAuthenticated = false;
  }
  if (!isAuthenticated) {
    next();
  } else {
    next("/users");
  }
}

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: SideMenu,
    beforeEnter: AuthCheck,
    children: [
      {
        path: "/lesson-list",
        name: "side-menu-lesson-list",
        meta: {
          title: 'Бүртгэлтэй хичээлийн жагсаалт',
        },
        component: lessonList
      },
      {
        path: "/add-lesson",
        name: "side-menu-add-lesson",
        meta: {
          title: 'Шинэ хичээл нэмэх',
        },
        component: addLesson
      },
      {
        path: "",
        name: "side-menu-users",
        meta: {
          title: 'Хэрэглэгчдийн жагсаалт'
        },
        component: users
      },
      {
        path: "inbox",
        name: "side-menu-inbox",
        component: Inbox
      },
      {
        path: "file-manager",
        name: "side-menu-file-manager",
        component: FileManager
      },
      {
        path: "point-of-sale",
        name: "side-menu-point-of-sale",
        component: PointOfSale
      },
      {
        path: "chat",
        name: "side-menu-chat",
        component: Chat
      },
      {
        path: "post",
        name: "side-menu-post",
        component: Post
      },
      {
        path: "crud-data-list",
        name: "side-menu-crud-data-list",
        component: CrudDataList
      },
      {
        path: "/online-race-text",
        name: "side-menu-online-race-text",
        meta: {
          title: 'Онлайн уралдааны текст'
        },
        component: OnlineRaceText
      },
      {
        path: "crud-form",
        name: "side-menu-crud-form",
        component: CrudForm
      },
      {
        path: "users-layout-1",
        name: "side-menu-users-layout-1",
        component: UsersLayout1
      },
      {
        path: "admins",
        name: "side-menu-users-layout-2",
        meta: {
          title: 'Админууд'
        },
        component: UsersLayout2
      },
      {
        path: "users-layout-3",
        name: "side-menu-users-layout-3",
        component: UsersLayout3
      },
      {
        path: "profile",
        name: "side-menu-profile",
        meta: {
          title: 'Хувийн мэдээлэл'
        },
        component: UpdateProfile
      },
      {
        path: "users",
        name: "side-menu-users",
        meta: {
          title: 'Хэрэглэгчдийн жагсаалт'
        },
        component: users
      },
      {
        path: "wizard-layout-1",
        name: "side-menu-wizard-layout-1",
        component: WizardLayout1
      },
      {
        path: "add-text",
        name: "side-menu-wizard-layout-2",
        meta: {
          title: 'Шинэ текст хадгалах'
        },
        component: WizardLayout2
      },
      {
        path: "add-operator",
        name: "side-menu-wizard-layout-3",
        meta: {
          title: 'Шинэ хэрэглэгч'
        },
        component: WizardLayout3
      },
      {
        path: "coupon-list",
        name: "side-menu-coupon-codes",
        meta: {
          title: 'Купон кодны жагсаалт'
        },
        component: CouponCodes
      },
      {
        path: "banners",
        name: "side-menu-banners",
        meta: {
          title: 'Баннерын жагсаалт'
        },
        component: banners
      },
      {
        path: "blog-layout-1",
        name: "side-menu-blog-layout-1",
        component: BlogLayout1
      },
      {
        path: "blog-layout-2",
        name: "side-menu-blog-layout-2",
        component: BlogLayout2
      },
      {
        path: "blog-layout-3",
        name: "side-menu-blog-layout-3",
        component: BlogLayout3
      },
      {
        path: "pricing-layout-1",
        name: "side-menu-pricing-layout-1",
        component: PricingLayout1
      },
      {
        path: "pricing-layout-2",
        name: "side-menu-pricing-layout-2",
        component: PricingLayout2
      },
      {
        path: "invoice-layout-1",
        name: "side-menu-invoice-layout-1",
        component: InvoiceLayout1
      },
      {
        path: "invoice-layout-2",
        name: "side-menu-invoice-layout-2",
        component: InvoiceLayout2
      },
      {
        path: "faq-layout-1",
        name: "side-menu-faq-layout-1",
        component: FaqLayout1
      },
      {
        path: "faq-layout-2",
        name: "side-menu-faq-layout-2",
        component: FaqLayout2
      },
      {
        path: "faq-layout-3",
        name: "side-menu-faq-layout-3",
        component: FaqLayout3
      },
      {
        path: "change-password",
        meta: {
          title: 'Нууц үг шинэчлэх'
        },
        name: "side-menu-change-password",
        component: ChangePassword
      },
      {
        path: "regular-table",
        name: "side-menu-regular-table",
        component: RegularTable
      },
      {
        path: "/texts",
        name: "side-menu-tabulator",
        meta: {
          title: 'Текстүүд'
        },
        component: Tabulator
      },
      {
        path: "/race-text",
        name: "side-menu-race-text",
        meta: {
          title: 'Шагналтай уралдааны текстүүд'
        },
        component: RaceText
      },
      {
        path: "accordion",
        name: "side-menu-accordion",
        component: Accordion
      },
      {
        path: "button",
        name: "side-menu-button",
        component: Button
      },
      {
        path: "modal",
        name: "side-menu-modal",
        component: Modal
      },
      {
        path: "alert",
        name: "side-menu-alert",
        component: Alert
      },
      {
        path: "progress-bar",
        name: "side-menu-progress-bar",
        component: ProgressBar
      },
      {
        path: "tooltip",
        name: "side-menu-tooltip",
        component: Tooltip
      },
      {
        path: "dropdown",
        name: "side-menu-dropdown",
        component: Dropdown
      },
      {
        path: "toast",
        name: "side-menu-toast",
        component: Toast
      },
      {
        path: "typography",
        name: "side-menu-typography",
        component: Typography
      },
      {
        path: "icon",
        name: "side-menu-icon",
        component: Icon
      },
      {
        path: "loading-icon",
        name: "side-menu-loading-icon",
        component: LoadingIcon
      },
      {
        path: "regular-form",
        name: "side-menu-regular-form",
        component: RegularForm
      },
      {
        path: "datepicker",
        name: "side-menu-datepicker",
        component: Datepicker
      },
      {
        path: "file-upload",
        name: "side-menu-file-upload",
        component: FileUpload
      },
      {
        path: "wysiwyg-editor",
        name: "side-menu-wysiwyg-editor",
        component: WysiwygEditor
      },
      {
        path: "validation",
        name: "side-menu-validation",
        component: Validation
      },
      {
        path: "chart",
        name: "side-menu-chart",
        component: Chart
      },
      {
        path: "slider",
        name: "side-menu-slider",
        component: Slider
      },
      {
        path: "image-zoom",
        name: "side-menu-image-zoom",
        component: ImageZoom
      },
      {
        path: "/dashboard",
        name: "side-menu-dashboard",
        component: Dashboard,
        meta: {
          title: 'Хяналтын самбар'
        }
      },
      {
        path: "/middle",
        name: "side-menu-middle",
        component: Middle
      }
    ]
  },
  {
    path: "/login",
    beforeEnter: LoginCheck,
    name: "login",
    component: Login
  },
  {
    path: "/register",
    beforeEnter: LoginCheck,
    name: "register",
    component: Register
  },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorPage
  },
  {
    path: "/*",
    component: ErrorPage
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
