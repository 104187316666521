<style scoped>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    text-align: center;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>
<template>
    <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
      <div class="col-span-12 mt-6">
        <div class="intro-y block sm:flex items-center h-10">
          <h2 class="text-lg font-medium truncate mr-5">Баннер жагсаалт</h2>
          <div class="flex items-center sm:ml-auto mt-3 sm:mt-0">
            <button
              class="button box flex items-center text-gray-700 dark:text-gray-300"
              @click="InputTrigger"
            >
              <SettingsIcon class="hidden sm:block w-4 h-4 mr-2" />
              Шинэ баннер оруулах
            </button>
            <input
                type="file"
                accept="image/*"
                ref="bannerInput"
                class="w-full h-full top-0 left-0 absolute"
                hidden
                @change="onImageSelected"
            />
          </div>
        </div>
        <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
          <table v-if="banner.list.length === 0" class="table table-report sm:mt-2">
            <tr>
              <td align="center">
                Идэвхитэй баннер алга байна
              </td>
            </tr>
          </table>


          <div v-else class="intro-y box p-5 mt-12 sm:mt-5">
            <el-carousel indicator-position="outside" :style="{'cursor': 'pointer'}">
                <el-carousel-item v-for="(item, index) in banner.list" :key="index">
                    <img
                    :src="item.image"
                    @click="setImage(item.image, item.id)"
                    />
                </el-carousel-item>
            </el-carousel>
        </div>
          
        </div>
      </div>
    </div>
    <div class="demo-image__preview">
      <el-image 
      hidden
        ref="bigImage"
        id="bigImage"
        style="width: 100px; height: 100px"
        :src="`https://api.hurdanhuruu.mn/images/race/default.jpeg`" 
        :preview-src-list="bigImageSource">
      </el-image>
    </div>

    <div id="question-modal" class="modal">
          <div class="modal__content">
              <div class="p-5 text-center">
                  <div class="mt-2"> 
                    <button class="button w-full inline-block mr-1 mb-2 border border-theme-1 text-theme-1 dark:border-theme-10 dark:text-theme-10" data-dismiss="modal" @click="showBigImage"> Томоор харах </button>
                    <button class="button w-full inline-block mr-1 mb-2 border border-theme-6 text-theme-6 dark:border-theme-6 dark:text-theme-6" data-dismiss="modal" @click="deleteBanner"> Устгах </button>
                  </div>
              </div>
          </div>
      </div>
    </div>
</template>
<script>
import Button from './Button.vue';
import TopMenu from "@/layouts/TopMenu";
export default {
  components: { Button, TopMenu},
    data() {
        return {
            stat: {
              val: true,
              valF: false
            },
            banner: {
                list: [],
            },
            bigImageSource: [''],
            selectedFile: '',
            selectedBannerId: 0
        }
    },
    mounted() {
      this.getBanners();
    },
    methods: {
        setImage(img, id) {
            this.bigImageSource = [img];
            this.selectedBannerId = id;
            cash('#question-modal').modal('show');
        },
        showBigImage() {
            const el = document.getElementById("bigImage");
            el.click();
        },
        getBanners() {
            const token = localStorage.getItem('token');
            var rts = this;
            if(token) {
                this.$axios({
                    method: 'post',
                    url: this.$appUrl+'/api/admin/get-banner',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(function(data) {
                    rts.banner.list = data.data.banners;
                });
            }
        },
        onImageSelected() {
            const file = event.target.files[0];
            this.selectedFile = file;
            this.addBanner();
        },
        addBanner () {
            var rts = this;
            const token = localStorage.getItem('token');
            if(this.selectedFile) {
                const fd = new FormData();
                fd.append('photo', this.selectedFile, this.selectedFile.name);
                this.$axios.post(this.$appUrl+'/api/admin/add-banner', 
                fd,
                {
                    headers: {
                    'Authorization': `Bearer ${token}`
                    }
                }
                )
                .then(function(data) {
                    rts.banner.list = data.data.banners;
                    rts.$message({
                        showClose: true,
                        type: 'success',
                        message: 'Амжилттай нэмэгдлээ'
                    });
                }).catch(error => {
                console.log(error);
                });
            }
        },
        InputTrigger() {
            this.$refs.bannerInput.click();
        },
        deleteBanner() {
            const token = localStorage.getItem('token');
            var rts = this;
            
            this.$confirm('Устгахдаа итргэлтэй байна уу', 'Санамж', {
                confirmButtonText: 'Тийм',
                cancelButtonText: 'Болих',
                type: 'warning'
            }).then(() => {
                if(token) {
                    rts.$axios({
                        method: 'post',
                        url: rts.$appUrl+'/api/admin/delete-banner',
                        headers: {
                            'Authorization': `Bearer ${token}`
                        },
                        data: {
                            id: rts.selectedBannerId
                        }
                    }).then(function(data) {
                        rts.banner.list = data.data.banners;
                        rts.$message({
                            showClose: true,
                            type: 'success',
                            message: 'Амжилттай устгагдлаа'
                        });
                    });
                }
            });
        }
    }
}
</script>