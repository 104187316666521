<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">Уралдааны жагсаалт</h2>
      <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="input w-56 box pr-10 placeholder-theme-13"
              placeholder="Search..."
              v-model="search"
              @keyup.enter="SearchRace"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
        </div>
    </div>
    <div class="overflow-x-auto">
      <table class="table mt-5">
         <thead>
             <tr class="bg-gray-700 dark:bg-dark-1 text-white">
                 <th class="whitespace-no-wrap" width="10%">#</th>
                 <th class="whitespace-no-wrap" width="50%">Текст</th>
                 <th class="whitespace-no-wrap" width="10%">Эхлэх огноо</th>
                 <th class="whitespace-no-wrap" width="10%">Дуусах огноо</th>
                 <th class="whitespace-no-wrap" width="8%">Ялагч тодорсон эсэх</th>
                 <th class="whitespace-no-wrap" width="12%"></th>
             </tr>
         </thead>
         <tbody>
             <tr v-for="(rows, index) in text" :key="index">
                 <td class="border-b dark:border-dark-5"><div class="ml-4 mr-auto"> <div class="font-medium">{{ rows.name }}</div> <div class="text-gray-600 text-xs"> #{{ rows.raceId }} </div> </div></td>
                 <td class="border-b dark:border-dark-5"> {{rows.text}} </td>
                 <td class="border-b dark:border-dark-5"> {{rows.start_at.split('T')[0]}} </td>
                 <td class="border-b dark:border-dark-5"> {{rows.end_at.split('T')[0]}} </td>
                 <td class="border-b dark:border-dark-5"> <span class="text-theme-6" v-if="rows.active == 1">Үгүй</span><span class="text-theme-9" v-else>Тийм</span> </td>
                 <td class="border-b dark:border-dark-5" align="left">
                   <el-tooltip class="item" effect="dark" content="Уралдааныг устгах" placement="top">
                     <button @click="onDelete(rows.raceId, index)" class="button px-2 mr-1 mb-2 bg-theme-6 text-white"> <span class="w-5 h-5 flex items-center justify-center"> <TrashIcon class="w-4 h-4" /> </span> </button>
                  </el-tooltip>
                   <el-tooltip class="item" effect="dark" content="Уралдааныг дуусгах" placement="top-end">
                     <button v-if="new Date(rows.end_at) < new Date() && rows.active == 1" @click="finishRace(rows.raceId)" class="button px-2 mr-1 mb-2 bg-theme-9 text-white"> <span class="w-5 h-5 flex items-center justify-center"> <ChevronsRightIcon class="w-4 h-4" /> </span> </button>
                  </el-tooltip>
                 </td>
             </tr>
         </tbody>
     </table>
    </div>
    <div id="search-result-modal" class="modal">
      <div class="modal__content modal__content--xl p-10 text-center">
        <div class="p-5">
                <div class="grid grid-cols-12 gap-5">
                  <div class="col-span-12 xl:col-span-4">
                    <div
                      class="border border-gray-200 dark:border-dark-5 rounded-md p-5"
                    >
                      <div
                        class="w-40 h-40 relative image-fit cursor-pointer zoom-in mx-auto"
                      >
                        <img
                          class="rounded-md"
                          :src="topuser.image"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-span-12 xl:col-span-8">
                    <div align="left">
                      <label>Уралдааны дугаар</label>
                      <input
                        type="text"
                        class="input w-full border bg-gray-100 cursor-not-allowed mt-2"
                        placeholder="Input text"
                        :value="race.raceId"
                        disabled
                      />
                    </div>
                    <div align="left" class="mt-3">
                      <label>Тэргүүлэгч</label>
                      <input
                        type="text"
                        class="input w-full border bg-gray-100 cursor-not-allowed mt-2"
                        placeholder="Input text"
                        :value="topuser.name"
                        disabled
                      />
                    </div>
                    <div align="left" class="mt-3">
                      <label>ТОП WPM</label>
                      <input
                        type="text"
                        class="input w-full border bg-gray-100 cursor-not-allowed mt-2"
                        placeholder="Input text"
                        :value="topuser.wpm + ' WPM'"
                        disabled
                      />
                    </div>
                    <div align="left" class="mt-3">
                      <label>Утасны дугаар</label>
                      <input
                        type="text"
                        class="input w-full border bg-gray-100 cursor-not-allowed mt-2"
                        placeholder="Input text"
                        :value="topuser.phone"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      text: [],
      winners: [],
      search: '',
      topuser: [],
      race: []
    }
  },
  mounted() {
    this.getText();
  },
  methods: {
    SearchRace() {
      let rts = this;
      const token = localStorage.getItem('token');
      this.$axios({
        method: 'POST',
        url: this.$appUrl+'/api/admin/search-main-race', 
        headers: {
              'Authorization': `Bearer ${token}`
          },
        data: {
          raceId: this.search
        }
        }).then(function(data) {
          rts.topuser = data.data.user[0];
          rts.race = data.data.race[0];
          cash('#search-result-modal').modal('show');
        }).catch(error => {
          console.log(error);
        });
    },
    getText() {
      let rts = this;
      const token = localStorage.getItem('token');
      this.$axios({
        method: 'POST',
        url: this.$appUrl+'/api/admin/get-race', 
        headers: {
              'Authorization': `Bearer ${token}`
          }
        }).then(function(data) {
          rts.text = data.data.data;
        }).catch(error => {
          console.log(error);
        });
    },
    onDelete(id, index) {
      let rts = this;
      this.$msg({
          title: 'Текст устгах',
          message:'Та устгахдаа итгэлтэй байна уу?',
          showCancelButton: true,
          confirmButtonText: 'Тийм',
          cancelButtonText: 'Болих',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = 'Түр хүлээнэ үү...';

              const token = localStorage.getItem('token');
              rts.$axios({
                method: 'POST',
                url: this.$appUrl+'/api/admin/delete-main-race', 
                headers: {
                      'Authorization': `Bearer ${token}`
                  },
                data: {
                  raceId: id
                }
              }).then(function(data) {
                instance.confirmButtonLoading = false;
                rts.text.splice(index, 1);
                done();
              }).catch(error => {
                  console.log(error);
                  instance.confirmButtonLoading = false;
              });
              
            } else {
              done();
            }
          }
        });
    },
    changeActiveStatus(id) {
      this.text.forEach(element => {
        if(element.raceId == id) {
          element.active = 0;
        }
      })
    },
    finishRace(id) {
      let rts = this;
      const token = localStorage.getItem('token');
      this.$axios({
        method: 'POST',
        url: this.$appUrl+'/api/admin/set-end-state-race', 
        headers: {
              'Authorization': `Bearer ${token}`
        },
        data: {
          raceId: id
        }
        }).then(function(data) {
          console.log(data);
          rts.$socket.emit('raceNotify', {
            raceId: id,
            users: data.data.result
          });
          rts.changeActiveStatus(id);
        })
    }
  }
};
</script>
