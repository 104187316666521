<template>
<div>
    <div class="col-span-12 grid grid-cols-12 gap-6 mt-8 mb-8">
        <div class="col-span-12 sm:col-span-1 xxl:col-span-1 intro-y">
        </div>
        <div class="col-span-12 sm:col-span-2 xxl:col-span-2 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex items-center absolute relative h-full justify-center top-0 left-0">
              <div class="w-2/4 flex-none">
                <div class="text-3xl font-medium truncate" align="center"><span class="text-gray-600">{{ checkRank(5) }}</span></div>
              </div>
              <div class="flex-none ml-auto flex">
                <div class="report-box zoom-in">
                  <div class="box p-5" :style="{'padding-left': '40px', 'padding-right': '40px'}">
                    <div class="text-3xl font-bold leading-8 mt-6">
                        <img :src="`${$appUrl}/images/rank/Expert.png`" :style="{'width': 'auto', 'height': '50px', 'margin': 'auto'}"/>
                    </div>
                    <div class="text-base text-gray-600 mt-2" align="center" :style="{'font-size': '12px'}">Expert</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-2 xxl:col-span-2 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex items-center absolute relative h-full justify-center top-0 left-0">
              <div class="w-2/4 flex-none">
                <div class="text-3xl font-medium truncate" align="center"><span class="text-gray-600">{{ checkRank(4) }}</span></div>
              </div>
              <div class="flex-none ml-auto flex">
                <div class="report-box zoom-in">
                  <div class="box p-5" :style="{'padding-left': '40px', 'padding-right': '40px'}">
                    <div class="text-3xl font-bold leading-8 mt-6">
                        <img :src="`${$appUrl}/images/rank/specialist.png`" :style="{'width': 'auto', 'height': '50px', 'margin': 'auto'}"/>
                    </div>
                    <div class="text-base text-gray-600 mt-2" align="center" :style="{'font-size': '12px'}">Specialist</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-2 xxl:col-span-2 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex items-center absolute relative h-full justify-center top-0 left-0">
              <div class="w-2/4 flex-none">
                <div class="text-3xl font-medium truncate" align="center"><span class="text-gray-600">{{ checkRank(3) }}</span></div>
              </div>
              <div class="flex-none ml-auto flex">
                <div class="report-box zoom-in">
                  <div class="box p-5" :style="{'padding-left': '40px', 'padding-right': '40px'}">
                    <div class="text-3xl font-bold leading-8 mt-6">
                        <img :src="`${$appUrl}/images/rank/skilled.png`" :style="{'width': 'auto', 'height': '50px', 'margin': 'auto'}"/>
                    </div>
                    <div class="text-base text-gray-600 mt-2" align="center" :style="{'font-size': '12px'}">Skilled</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-2 xxl:col-span-2 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex items-center absolute relative h-full justify-center top-0 left-0">
              <div class="w-2/4 flex-none">
                <div class="text-3xl font-medium truncate" align="center"><span class="text-gray-600">{{ checkRank(2) }}</span></div>
              </div>
              <div class="flex-none ml-auto flex">
                <div class="report-box zoom-in">
                  <div class="box p-5" :style="{'padding-left': '40px', 'padding-right': '40px'}">
                    <div class="text-3xl font-bold leading-8 mt-6">
                        <img :src="`${$appUrl}/images/rank/average.png`" :style="{'width': 'auto', 'height': '50px', 'margin': 'auto'}"/>
                    </div>
                    <div class="text-base text-gray-600 mt-2" align="center" :style="{'font-size': '12px'}">Average</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-2 xxl:col-span-2 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex items-center absolute relative h-full justify-center top-0 left-0">
              <div class="w-2/4 flex-none">
                <div class="text-3xl font-medium truncate" align="center"><span class="text-gray-600">{{ checkRank(1) }}</span></div>
              </div>
              <div class="flex-none ml-auto flex">
                <div class="report-box zoom-in">
                  <div class="box p-5" :style="{'padding-left': '40px', 'padding-right': '40px'}">
                    <div class="text-3xl font-bold leading-8 mt-6">
                        <img :src="`${$appUrl}/images/rank/beginner.png`" :style="{'width': 'auto', 'height': '50px', 'margin': 'auto'}"/>
                    </div>
                    <div class="text-base text-gray-600 mt-2" align="center" :style="{'font-size': '12px'}">Beginner</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-1 xxl:col-span-1 intro-y">
        </div>
    </div>
    <div class="intro-y box col-span-12 lg:col-span-3">
        <div class="flex items-center px-5 py-5 sm:py-0 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base mr-auto py-5">Хэрэглэгчдийн жагсаалт</h2>
        </div>
        
        <div class="p-5">
            <div class="tab-content intro-y" align="center" :style="{'overflow': 'auto'}">
                <table :style="{'width': '95%', 'min-width': '1000px'}">
                    <tr
                    v-for="(users, index) in this.users.list"
                    :key="index"
                    class="box px-4 py-4 mb-3 flex items-center zoom-in">
                        <td width="15%"><div class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden"> <img :src="`${users.image}`" /> </div></td>
                        <td width="25%">
                            <div class="ml-4 mr-auto"> <div class="font-medium">{{ users.name }}</div> <div class="text-gray-600 text-xs"> #{{ users.email }} </div> </div>
                        </td>
                        <td width="20%">
                            <div class="text-gray-600 whitespace-no-wrap" :style="{'font-size': '15px'}">
                                {{ users.phone }}
                            </div>
                        </td>
                        <td width="10%">
                            <b>{{ users.record_wpm }} WPM</b>
                        </td>
                        <td width="20%" align="center">
                            <img :src="`${$appUrl}/images/rank/${users.rimage}`" :style="{'width': 'auto', 'height': '40px', 'margin': 'auto'}"/>
                        </td>
                        <td width="10%">
                            <b>{{ users.rname }}</b>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
</template>
<script>
  export default {
    data () {
      return {
        count: 0,
        users: {
            list: [],
            count: []
        }
      }
    },
    mounted() {
        this.getFriends();
    },
    methods: {
      load () {
        this.count += 1
      },
      getFriends() {
        const token = localStorage.getItem('token');
        var rts = this;
        if(token) {
            this.$axios({
                method: 'post',
                url: this.$appUrl+'/api/admin/all-users',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: {
                    count: this.count
                }
            }).then(function(data) {
                rts.users.list = data.data.users;
                rts.users.count = data.data.count
            });
        }
      },
      checkRank(id) {
          let count = 0;
          let stage = this.users.count.some(function(field) {
              return field.id == id;
          });

          if(stage) {
              this.users.count.forEach(element => {
                  if(element.id == id) {
                      count = element.count;
                  }
              });
          }
          return count;
      } 
    }
  }
</script>