<template>
  <div>
    <div class="col-span-12 grid grid-cols-12 gap-6 mt-8 mb-8">
        <div class="col-span-12 sm:col-span-1 xxl:col-span-1 intro-y">
        </div>
        <div class="col-span-12 sm:col-span-2 xxl:col-span-2 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex items-center absolute relative h-full justify-center top-0 left-0">
              <div class="w-2/4 flex-none">
                <div class="text-3xl font-medium truncate" align="center"><span class="text-gray-600">{{ checkRank(5) }}</span></div>
              </div>
              <div class="flex-none ml-auto flex">
                <div class="report-box zoom-in">
                  <div class="box p-5" :style="{'padding-left': '40px', 'padding-right': '40px'}">
                    <div class="text-3xl font-bold leading-8 mt-6">
                        <img :src="`${$appUrl}/images/rank/Expert.png`" :style="{'width': 'auto', 'height': '50px', 'margin': 'auto'}"/>
                    </div>
                    <div class="text-base text-gray-600 mt-2" align="center" :style="{'font-size': '12px'}">Expert</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-2 xxl:col-span-2 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex items-center absolute relative h-full justify-center top-0 left-0">
              <div class="w-2/4 flex-none">
                <div class="text-3xl font-medium truncate" align="center"><span class="text-gray-600">{{ checkRank(4) }}</span></div>
              </div>
              <div class="flex-none ml-auto flex">
                <div class="report-box zoom-in">
                  <div class="box p-5" :style="{'padding-left': '40px', 'padding-right': '40px'}">
                    <div class="text-3xl font-bold leading-8 mt-6">
                        <img :src="`${$appUrl}/images/rank/specialist.png`" :style="{'width': 'auto', 'height': '50px', 'margin': 'auto'}"/>
                    </div>
                    <div class="text-base text-gray-600 mt-2" align="center" :style="{'font-size': '12px'}">Specialist</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-2 xxl:col-span-2 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex items-center absolute relative h-full justify-center top-0 left-0">
              <div class="w-2/4 flex-none">
                <div class="text-3xl font-medium truncate" align="center"><span class="text-gray-600">{{ checkRank(3) }}</span></div>
              </div>
              <div class="flex-none ml-auto flex">
                <div class="report-box zoom-in">
                  <div class="box p-5" :style="{'padding-left': '40px', 'padding-right': '40px'}">
                    <div class="text-3xl font-bold leading-8 mt-6">
                        <img :src="`${$appUrl}/images/rank/skilled.png`" :style="{'width': 'auto', 'height': '50px', 'margin': 'auto'}"/>
                    </div>
                    <div class="text-base text-gray-600 mt-2" align="center" :style="{'font-size': '12px'}">Skilled</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-2 xxl:col-span-2 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex items-center absolute relative h-full justify-center top-0 left-0">
              <div class="w-2/4 flex-none">
                <div class="text-3xl font-medium truncate" align="center"><span class="text-gray-600">{{ checkRank(2) }}</span></div>
              </div>
              <div class="flex-none ml-auto flex">
                <div class="report-box zoom-in">
                  <div class="box p-5" :style="{'padding-left': '40px', 'padding-right': '40px'}">
                    <div class="text-3xl font-bold leading-8 mt-6">
                        <img :src="`${$appUrl}/images/rank/Average.png`" :style="{'width': 'auto', 'height': '50px', 'margin': 'auto'}"/>
                    </div>
                    <div class="text-base text-gray-600 mt-2" align="center" :style="{'font-size': '12px'}">Average</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-2 xxl:col-span-2 intro-y">
          <div class="mini-report-chart box p-5 zoom-in">
            <div class="flex items-center absolute relative h-full justify-center top-0 left-0">
              <div class="w-2/4 flex-none">
                <div class="text-3xl font-medium truncate" align="center"><span class="text-gray-600">{{ checkRank(1) }}</span></div>
              </div>
              <div class="flex-none ml-auto flex">
                <div class="report-box zoom-in">
                  <div class="box p-5" :style="{'padding-left': '40px', 'padding-right': '40px'}">
                    <div class="text-3xl font-bold leading-8 mt-6">
                        <img :src="`${$appUrl}/images/rank/beginner.png`" :style="{'width': 'auto', 'height': '50px', 'margin': 'auto'}"/>
                    </div>
                    <div class="text-base text-gray-600 mt-2" align="center" :style="{'font-size': '12px'}">Beginner</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-1 xxl:col-span-1 intro-y">
        </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap items-center mt-2"
      >
        <div class="hidden md:block mx-auto text-gray-600">
        </div>
        <el-tooltip class="item" effect="dark" content="Хар жагсаалтын хэрэглэгчид" placement="top">
        <el-switch
          class="pr-5"
          v-model="showBlockUser"
          active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch>
        </el-tooltip>
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="input w-56 box pr-10 placeholder-theme-13"
              placeholder="Search..."
              v-model="search"
              @keyup.enter="searchUser"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div v-if="showBlockUser == 0" class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-10">
          <thead>
            <tr>
              <th class="whitespace-no-wrap">Зураг</th>
              <th class="whitespace-no-wrap">Нэр</th>
              <th class="text-center whitespace-no-wrap">Утасны дугаар</th>
              <th class="text-center whitespace-no-wrap">Хичээл (Lvl)</th>
              <th class="text-center whitespace-no-wrap">ТОП WPM</th>
              <th class="text-center whitespace-no-wrap">Цол</th>
              <th class="text-center whitespace-no-wrap"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(users, index) in filterUser()"
              :key="index"
              class="intro-x"
            >
              <td class="w-40">
                <div class="flex">
                  <div class="w-10 h-10 image-fit zoom-in">
                    <Tippy
                      tag="img"                      
                      class="rounded-full"
                      :src="users.image"
                    />
                  </div>
                </div>
              </td>
              <td>
                <a href="" class="font-medium whitespace-no-wrap">{{
                  users.name
                }}</a>
                <div class="text-gray-600 text-xs whitespace-no-wrap">
                  #{{ users.id }}
                </div>
              </td>
              <td class="text-center">{{ users.phone }}</td>
              <td class="text-center">{{ users.lesson }}</td>
              <td class="text-center">{{users.record_wpm}}</td>
              <td class="w-40">
                <img :src="`${$appUrl}/images/rank/${users.rimage}`" :style="{'width': 'auto', 'height': '40px', 'margin': 'auto', 'box-shadow': 'none'}"/>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;">
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Edit
                  </a>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    @click="BlockUser(users.id)"
                  >
                    <PowerIcon class="w-4 h-4 mr-1" /> Block
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-10">
          <thead>
            <tr>
              <th class="whitespace-no-wrap">Зураг</th>
              <th class="whitespace-no-wrap">Нэр</th>
              <th class="text-center whitespace-no-wrap">Утасны дугаар</th>
              <th class="text-center whitespace-no-wrap">Хичээл (Lvl)</th>
              <th class="text-center whitespace-no-wrap">ТОП WPM</th>
              <th class="text-center whitespace-no-wrap"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(users, index) in blackList"
              :key="index"
              class="intro-x"
            >
              <td class="w-40">
                <div class="flex">
                  <div class="w-10 h-10 image-fit zoom-in">
                    <Tippy
                      tag="img"                      
                      class="rounded-full"
                      :src="users.image"
                    />
                  </div>
                </div>
              </td>
              <td>
                <a href="" class="font-medium whitespace-no-wrap">{{
                  users.name
                }}</a>
                <div class="text-gray-600 text-xs whitespace-no-wrap">
                  #{{ users.id }}
                </div>
              </td>
              <td class="text-center">{{ users.phone }}</td>
              <td class="text-center">{{ users.lesson }}</td>
              <td class="text-center">{{users.record_wpm}}</td>
              
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;">
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Edit
                  </a>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    @click="UnBlockUser(users.id)"
                  >
                    <PowerIcon class="w-4 h-4 mr-1" /> Unblock
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <div v-if="showBlockUser == 0"
        class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-no-wrap items-center"
      >
        <ul class="pagination" v-if="totalPage > 1">
          <li v-if="this.page > 1">
            <a class="pagination__link" href="javascript:;" @click="changePage(1)">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>  

          <li v-if="page > 5 && totalPage > 10">
            <a class="pagination__link" href="javascript:;" @click="changePage(1)">1</a>
          </li>

          <li v-if="page > 5 && totalPage > 10">
              <a class="pagination__link" href="javascript:;"> ... </a>
          </li>
          <li v-for="(item, index) in range(page, page + 3)" :key="index">
              <a class="pagination__link" href="javascript:;" @click="changePage(item)" v-bind:class="{'pagination__link--active': page == item}">{{item}}</a>
          </li>
          <li v-if="totalPage - 3 > page">
              <a class="pagination__link" href="javascript:;"> ... </a>
          </li>
          <li v-for="(item, index) in endrange()" :key="index+'m'">
              <a class="pagination__link" href="javascript:;" @click="changePage(item)" v-bind:class="{'pagination__link--active': page == item}">{{item}}</a>
          </li>
        </ul>
        <select class="w-20 input box mt-3 sm:mt-0" @change="changeGroup($event)">
          <option>25</option>
          <option>50</option>
        </select>
      </div>

      <!-- <pagination-page :settings="settings">
        <div slot="page" slot-scope="item">
          <div>{{item.originalEvent.data}}</div>
        </div>
      </pagination-page>
      <pagination-control :settings="settings" /> -->

    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        currentPage: 1,
        users: {
            list: [],
            count: []
        },
        paginate: {
          start: 0
        },
        page: 1,
        pageGroup: 25,
        totalPage: 0,
        search: '',
        showBlockUser: 0,
        blackList: []
      }
    },
    mounted() {
        this.getUsers();
    },
    methods: {
      filterUser(id) {
        var us = [];
        this.users.list.forEach(el => {
          let u = this.blackList.some(function(field) {
              return field.id == el.id;
          });
          if(!u) {
            us.push(el); 
          }
        });
        return us;
      },
      UnBlockUser(id) {
        const token = localStorage.getItem('token');
        let rts = this;
        if(token) {
            this.$msg({
            title: 'Хар жагсаалтаас хасах',
            message:'Итгэлтэй байна уу?',
            showCancelButton: true,
            confirmButtonText: 'Тийм',
            cancelButtonText: 'Болих',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                  instance.confirmButtonLoading = true;
                  instance.confirmButtonText = 'Түр хүлээнэ үү...';
                  rts.$axios({
                    method: 'post',
                    url: rts.$appUrl+'/api/admin/unblock-user',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    data: {
                        id
                    }
                }).then(function(data) {
                    if(data.data.result == 'success') {
                      instance.confirmButtonLoading = false;
                      instance.confirmButtonText = 'Тийм';
                      done();
                      rts.blackList.forEach((el, index) => {
                        if(el.id == id) {
                          rts.users.list.push(el);
                          rts.blackList.splice(index, 1);
                        }
                      });
                    }
                });
              } else {
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = 'Тийм';
                done();
              }
            }
          });
        }
      },
      BlockUser(id) {
        const token = localStorage.getItem('token');
        let rts = this;
        if(token) {
            this.$msg({
            title: 'Хар жагсаалтанд нэмэх',
            message:'Итгэлтэй байна уу?',
            showCancelButton: true,
            confirmButtonText: 'Тийм',
            cancelButtonText: 'Болих',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                  instance.confirmButtonLoading = true;
                  instance.confirmButtonText = 'Түр хүлээнэ үү...';
                  rts.$axios({
                    method: 'post',
                    url: rts.$appUrl+'/api/admin/block-user',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    data: {
                        id
                    }
                }).then(function(data) {
                    if(data.data.result == 'success') {
                      instance.confirmButtonLoading = false;
                      instance.confirmButtonText = 'Тийм';
                      done();
                      rts.users.list.forEach((el, index) => {
                        if(el.id == id) {
                          rts.blackList.push(el);
                          rts.users.list.splice(index, 1);
                        }
                      });
                    }
                });
              } else {
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = 'Тийм';
                done();
              }
            }
          });
        }
      },
      endrange () {
        var array = [],
        j = 0;
        if (this.totalPage > this.page + 3) {
          array[0] = this.totalPage;
          return array;
        }
      },
      range: function(min,max){
        var array = [],
        j = 0;
        if(max > this.totalPage) {
          max = this.totalPage;
        }
        if (min > this.totalPage - 4) {
          for(var i = this.totalPage - 4; i <= max; i++){
            array[j] = i;
            j++;
          }
        } else {
          if(this.page > 5) {
            for(var i = this.page - 2; i <= this.page + 2; i++){
              array[j] = i;
              j++;
            }
          } else {
            for(var i = 1; i <= 6; i++){
              array[j] = i;
              j++;
            }
          }
        }
        return array;
      },
      clickCallback (pageNum) {
        console.log(pageNum)
      },
      getUsers() {
        const token = localStorage.getItem('token');
        var rts = this;
        if(token) {
            this.$axios({
                method: 'post',
                url: this.$appUrl+'/api/admin/all-users',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: {
                    page: this.page,
                    pageGroup: this.pageGroup,
                    name: this.search
                }
            }).then(function(data) {
                rts.users.count = data.data.count
                rts.users.list = data.data.users;
                rts.totalPage = data.data.pagination;
                rts.blackList = data.data.blacklist;
            });
        }
      },
      checkRank(id) {
          let count = 0;
          let stage = this.users.count.some(function(field) {
              return field.id == id;
          });

          if(stage) {
              this.users.count.forEach(element => {
                  if(element.id == id) {
                      count = element.count;
                  }
              });
          }
          return count;
      },
      changePage(pg) {
        if(pg != this.page) {
          this.page = pg;
          this.getUsers();
        }
      },
      changeGroup(e) {
        this.pageGroup = e.target.value;
        this.getUsers();
      },
      searchUser() {
        this.getUsers();
      }
    }
  }
</script>