<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Хэрэглэгчид</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap items-center mt-2"
      >
        <button @click="newOperator" class="button text-white bg-theme-1 shadow-md mr-2">
          Шинэ хэрэглэгч бүртгэх
        </button>
        
        <div class="hidden md:block mx-auto text-gray-600">
          <!-- Showing 1 to 10 of 150 entries -->
        </div>

        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="input w-56 box pr-10 placeholder-theme-13"
              placeholder="Хайлт..."
              v-model="search"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div>
      </div>
      <!-- BEGIN: Users Layout -->


      <div
        v-for="(admin, index) in admins"
        :key="index"
        class="intro-y col-span-12 md:col-span-6"
      >
        <div class="box" v-if="(admin.name).toUpperCase().includes(search.toUpperCase())">
          <div class="flex flex-col lg:flex-row items-center p-5">
            <div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
              <img
                alt="Midone Tailwind HTML Admin Template"
                class="rounded-full"
                :src="`${admin.image}`"
              />
            </div>
            <div
              class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0"
            >
              <a href="" class="font-medium">{{ admin.name }}</a>
              <div class="text-gray-600 text-xs">{{ admin.email }}</div>
            </div>
            <div class="flex mt-4 lg:mt-0">
              <button class="button button--sm text-white bg-theme-1 mr-2">
                Цааш 
              </button>
              <a
                class="button border relative flex items-center text-gray-700 dark:border-dark-5 dark:text-gray-300 sm:flex"
                :style="{'width': '113px'}"
              >
               <MicIcon
                  class="w-4 h-4 mr-2"
                /> {{admin.permission}}
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      admins: [],
      search: ''
    }
  },
  mounted() {
    this.getOperators();
  },
  methods: {
    getOperators () {
      let rts = this;
      const token = localStorage.getItem('token');
      this.$axios({
        method: 'POST',
        url: this.$appUrl+'/api/admin/get-admins', 
        headers: {
              'Authorization': `Bearer ${token}`
            }
        }).then(function(data) {
          rts.admins = data.data.users;
        });
    },
    newOperator(){
      this.$router.push('/add-operator');
    }
  }
}
</script>
