var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"grid grid-cols-12 gap-6 mt-5"},[_c('div',{staticClass:"intro-y col-span-12 lg:col-span-6"},[_c('div',{staticClass:"intro-y box"},[_vm._m(1),_c('div',{staticClass:"p-5",attrs:{"id":"single-file-upload"}},[_c('div',{staticClass:"preview"},[_c('VueDropzone',{staticClass:"dropzone font-roboto border-gray-200 border-dashed text-gray-800 hover:bg-transparent",attrs:{"id":"dropzone-single","use-custom-slot":true,"options":{
                url: 'https://httpbin.org/post',
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                maxFiles: 1,
                headers: { 'My-Awesome-Header': 'header value' }
              }}},[_c('div',{staticClass:"text-lg font-medium"},[_vm._v(" Drop files here or click to upload. ")]),_c('div',{staticClass:"text-gray-600"},[_vm._v(" This is just a demo dropzone. Selected files are "),_c('span',{staticClass:"font-medium"},[_vm._v("not")]),_vm._v(" actually uploaded. ")])])],1),_c('div',{staticClass:"source-code hidden"},[_c('button',{staticClass:"copy-code button button--sm border flex items-center text-gray-700 dark:border-dark-5 dark:text-gray-300",attrs:{"data-target":"#copy-single-file-upload"}},[_c('FileIcon',{staticClass:"w-4 h-4 mr-2"}),_vm._v(" Copy example code ")],1),_c('div',{staticClass:"overflow-y-auto h-64 mt-3"},[_c('Highlight',{staticClass:"source-preview",attrs:{"id":"copy-single-file-upload"}},[_c('code',{staticClass:"text-xs p-0 rounded-md html pl-5 pt-8 pb-4 -mb-10 -mt-10"},[_c('textarea',[_vm._v(" <VueDropzone id=\"dropzone-single\" :use-custom-slot=\"true\" :options=\"{ url: 'https://httpbin.org/post', thumbnailWidth: 150, maxFilesize: 0.5, maxFiles: 1, headers: { 'My-Awesome-Header': 'header value' } }\" class=\"dropzone font-roboto border-gray-200 border-dashed text-gray-800 hover:bg-transparent\" > <div class=\"text-lg font-medium\"> Drop files here or click to upload. </div> <div class=\"text-gray-600\"> This is just a demo dropzone. Selected files are <span class=\"font-medium\">not</span> actually uploaded. </div> </VueDropzone> ")])])])],1)])])]),_c('div',{staticClass:"intro-y box mt-5"},[_vm._m(2),_c('div',{staticClass:"p-5",attrs:{"id":"multiple-file-upload"}},[_c('div',{staticClass:"preview"},[_c('VueDropzone',{staticClass:"dropzone font-roboto border-gray-200 border-dashed text-gray-800 hover:bg-transparent",attrs:{"id":"dropzone-multiple","use-custom-slot":true,"options":{
                url: 'https://httpbin.org/post',
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                headers: { 'My-Awesome-Header': 'header value' }
              }}},[_c('div',{staticClass:"text-lg font-medium"},[_vm._v(" Drop files here or click to upload. ")]),_c('div',{staticClass:"text-gray-600"},[_vm._v(" This is just a demo dropzone. Selected files are "),_c('span',{staticClass:"font-medium"},[_vm._v("not")]),_vm._v(" actually uploaded. ")])])],1),_c('div',{staticClass:"source-code hidden"},[_c('button',{staticClass:"copy-code button button--sm border flex items-center text-gray-700 dark:border-dark-5 dark:text-gray-300",attrs:{"data-target":"#copy-multiple-file-upload"}},[_c('FileIcon',{staticClass:"w-4 h-4 mr-2"}),_vm._v(" Copy example code ")],1),_c('div',{staticClass:"overflow-y-auto h-64 mt-3"},[_c('Highlight',{staticClass:"source-preview",attrs:{"id":"copy-multiple-file-upload"}},[_c('code',{staticClass:"text-xs p-0 rounded-md html pl-5 pt-8 pb-4 -mb-10 -mt-10"},[_c('textarea',[_vm._v(" <VueDropzone id=\"dropzone-multiple\" :use-custom-slot=\"true\" :options=\"{ url: 'https://httpbin.org/post', thumbnailWidth: 150, maxFilesize: 0.5, headers: { 'My-Awesome-Header': 'header value' } }\" class=\"dropzone font-roboto border-gray-200 border-dashed text-gray-800 hover:bg-transparent\" > <div class=\"text-lg font-medium\"> Drop files here or click to upload. </div> <div class=\"text-gray-600\"> This is just a demo dropzone. Selected files are <span class=\"font-medium\">not</span> actually uploaded. </div> </VueDropzone> ")])])])],1)])])])]),_c('div',{staticClass:"intro-y col-span-12 lg:col-span-6"},[_c('div',{staticClass:"intro-y box"},[_vm._m(3),_c('div',{staticClass:"p-5",attrs:{"id":"file-type-validation"}},[_c('div',{staticClass:"preview"},[_c('VueDropzone',{staticClass:"dropzone font-roboto border-gray-200 border-dashed text-gray-800 hover:bg-transparent",attrs:{"id":"dropzone-validation","use-custom-slot":true,"options":{
                url: 'https://httpbin.org/post',
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                acceptedFiles: 'image/jpeg|image/png|image/jpg',
                headers: { 'My-Awesome-Header': 'header value' }
              }},on:{"vdropzone-error":_vm.dropzoneError}},[_c('div',{staticClass:"text-lg font-medium"},[_vm._v(" Drop files here or click to upload. ")]),_c('div',{staticClass:"text-gray-600"},[_vm._v(" This is just a demo dropzone. Selected files are "),_c('span',{staticClass:"font-medium"},[_vm._v("not")]),_vm._v(" actually uploaded. ")])])],1),_c('div',{staticClass:"source-code hidden"},[_c('button',{staticClass:"copy-code button button--sm border flex items-center text-gray-700 dark:border-dark-5 dark:text-gray-300",attrs:{"data-target":"#copy-file-type-validation"}},[_c('FileIcon',{staticClass:"w-4 h-4 mr-2"}),_vm._v(" Copy example code ")],1),_c('div',{staticClass:"overflow-y-auto h-64 mt-3"},[_c('Highlight',{staticClass:"source-preview",attrs:{"id":"copy-file-type-validation"}},[_c('code',{staticClass:"text-xs p-0 rounded-md html pl-5 pt-8 pb-4 -mb-10 -mt-10"},[_c('textarea',[_vm._v(" <VueDropzone id=\"dropzone-validation\" :use-custom-slot=\"true\" :options=\"{ url: 'https://httpbin.org/post', thumbnailWidth: 150, maxFilesize: 0.5, acceptedFiles: 'image/jpeg|image/png|image/jpg', headers: { 'My-Awesome-Header': 'header value' } }\" @vdropzone-error=\"dropzoneError\" class=\"dropzone font-roboto border-gray-200 border-dashed text-gray-800 hover:bg-transparent\" > <div class=\"text-lg font-medium\"> Drop files here or click to upload. </div> <div class=\"text-gray-600\"> This is just a demo dropzone. Selected files are <span class=\"font-medium\">not</span> actually uploaded. </div> </VueDropzone> ")])])])],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"intro-y flex items-center mt-8"},[_c('h2',{staticClass:"text-lg font-medium mr-auto"},[_vm._v("Dropzone")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"},[_c('h2',{staticClass:"font-medium text-base mr-auto"},[_vm._v("Single File Upload")]),_c('div',{staticClass:"w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"},[_c('div',{staticClass:"mr-3"},[_vm._v("Show example code")]),_c('input',{staticClass:"show-code input input--switch border",attrs:{"data-target":"#single-file-upload","type":"checkbox"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"},[_c('h2',{staticClass:"font-medium text-base mr-auto"},[_vm._v("Multiple File Upload")]),_c('div',{staticClass:"w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"},[_c('div',{staticClass:"mr-3"},[_vm._v("Show example code")]),_c('input',{staticClass:"show-code input input--switch border",attrs:{"data-target":"#multiple-file-upload","type":"checkbox"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"},[_c('h2',{staticClass:"font-medium text-base mr-auto"},[_vm._v("File Type Validation")]),_c('div',{staticClass:"w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"},[_c('div',{staticClass:"mr-3"},[_vm._v("Show example code")]),_c('input',{staticClass:"show-code input input--switch border",attrs:{"data-target":"#file-type-validation","type":"checkbox"}})])])}]

export { render, staticRenderFns }