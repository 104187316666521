<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <router-link to="/beginner" class="-intro-x flex items-center pt-5">
            <img
              alt="Midone Tailwind HTML Admin Template"
              class="w-6"
              src="@/assets/images/logo/logo.png"
              style="width: 90px; height: auto;"
            />
          </router-link>
          <div class="my-auto">
            <img
              alt="Midone Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              A few more clicks to <br />
              sign in to your account.
            </div>
            <div class="-intro-x mt-5 text-lg text-white dark:text-gray-500">
              Manage all your e-commerce accounts in one place
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Sign In
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              A few more clicks to sign in to your account. Manage all your
              e-commerce accounts in one place
            </div>
            <form @submit.prevent="Login">
            <div class="intro-x mt-8">
              <input
                type="text"
                class="intro-x login__input input input--lg border border-gray-300 block"
                placeholder="Email"
                v-model="model.email"
              />
              <input
                type="password"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                v-model="model.password"
                placeholder="Password"
              />
            </div>
            <div
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
              <div class="flex items-center mr-auto">
               
              </div>
              <a href="javascript:;" data-toggle="modal" data-target="#forgot-password-modal">Нууц үгээ мартсан уу?</a>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                <button
                  type="submit"
                  class="button button--lg w-full xl:w-40 text-white bg-theme-1 xl:mr-3 align-top"
                  :disabled='texts.isDisabled'
                  v-html="loginBtn"
                >
                </button>
                
                <button
                  class="button button--lg w-full xl:w-32 text-gray-700 border border-gray-300 dark:border-dark-5 dark:text-gray-300 mt-3 xl:mt-0 align-top"
                  @click="signup"
                >
                  Бүртгүүлэх
                </button>
            </div>
            </form>
            <div
              class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left"
            >
              Нэвтэрч орсноор
              <a class="text-theme-1 dark:text-theme-10" href=""
                >Terms and Conditions</a
              >
              &
              <a class="text-theme-1 dark:text-theme-10" href=""
                >Privacy Policy</a
              > <br /> зөвшөөрч байна.
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
    <div id="forgot-password-modal" class="modal">
        <div class="modal__content px-5 py-10">
            <form @submit.prevent="forgotPassword">
              <div class="text-center">
                  <div class="mb-3"> Бүртгэлтэй и-мэйл хаягаа оруулснаар тухайн хаягаар таны шинэчлэгдсэн нууц үг <b>нууц үг</b> очих болно.! </div>
              </div>
              <div class="p-5 grid grid-cols-12 gap-4 row-gap-3">
                  <div class="col-span-12 sm:col-span-12"> <label>To:</label> <input type="email" v-model="forgot.email" required class="input w-full border mt-2 flex-1" placeholder="example@gmail.com" /> </div>
              </div>
              <div class="px-5 py-3 text-right border-t border-gray-200 dark:border-dark-5"> 
                <button type="button" data-dismiss="modal" class="button w-20 border text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"> Болих </button> 
                <button type="submit" class="button w-40 bg-theme-1 text-white" :disabled="forgot.isDisabled"> {{this.forgot.btn}} </button> 
              </div>
            </form>
        </div>
    </div>


    <div id="password-changed-modal" class="modal" ref="password_changed_modal">
        <div class="modal__content">
            <div class="p-5 text-center">
                <CheckCircleIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
                <div class="text-2xl mt-5">Амжилтай шинэчлэгдлээ!</div>
                <div class="text-gray-600 mt-2"> Таны шинэ нууц үг таны и-мэйл хаяг руу илгээгдлээ! </div>
            </div>
            <div class="px-5 pb-8 text-center"> <button type="button" data-dismiss="modal" class="button w-24 bg-theme-1 text-white"> Ok </button> </div>
        </div>
    </div>


  </div>
</template>

<script>
import DarkModeSwitcher from "@/components/DarkModeSwitcher";
import Toastify from "toastify-js";
import Vue from "vue";
import { request } from 'http';
export default {
  components: {
    DarkModeSwitcher
  },
  mounted() {
    cash("body")
      .removeClass("app")
      .addClass("login");

    if(this.$route.query.sms) {
        this.$message({
              message: 'И-Мэйл хаяг амжилттай баталгаажлаа.',
              type: 'success'
        });
    }
  },
  data() {
      return {
        model: {
          email: 'badarch.ogino@gmail.com',
          password: '',
          fullscreenLoading: false
          // rememberMe: false
        },
        texts: {
          loginBtn: 'Нэвтрэх',
          isDisabled : false
        },
        forgot: {
          email: '',
          btn: 'Илгээх',
          isDisabled : false
        }
      };
    },
  computed: {
      loginBtn: function() {
        return this.texts.loginBtn;
      }
    },
  methods: {
    Login() {
      var rts = this;
        rts.texts.isDisabled = true;
        this.texts.loginBtn = 'Уншиж байна..';
       
       this.$axios({
          method: 'post',
          url: rts.$appUrl +'/api/admin/login',
          headers: {}, 
          data: {
            email: this.model.email,
            password: this.model.password
          }
        }).then(function(data) {
          console.log(data);
        if(data.data.result == 'success') {
            rts.$message({
              message: 'Системд амжилттай нэвтэрлээ',
              type: 'success'
            });
            localStorage.user = JSON.stringify(data.data.user);
            localStorage.token = data.data.token;
            // rts.$router.push('/users');
            setTimeout(() => {
              window.location.href = window.location.protocol + '//' + window.location.host + '/users';
            }, 1000);
          } else {
            rts.texts.isDisabled = false;
            rts.texts.loginBtn = 'Нэвтрэх';
            rts.$message({
              message: data.data.data,
              type: 'error'
            });
          }
        }).catch(err => {
          console.log(err);
        });

    },
    signup() {
      this.$router.push('/users');
    }, 
    forgotPassword() {
      var rts = this;
      rts.forgot.btn = 'Уншиж байна ...';
      rts.forgot.isDisabled = true;
      console.log(this.forgot.email);
      this.$axios({
          method: 'post',
          url: this.$appUrl+'/api/admin/forgot-password',
          data: {
            email: rts.forgot.email
          }
      }).then(function(){
        rts.forgot.btn = 'Илгээх';
        rts.forgot.isDisabled = false;
        cash("#password-changed-modal").modal("show");
      }).catch(error => {
        console.log(error);
      });
    }
  }
};
</script>
