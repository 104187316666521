<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 19px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #13ce66;
}

input:focus + .slider {
  box-shadow: 0 0 1px #13ce66;
}

input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap items-center mt-2"
      >
        <div class="hidden md:block mx-auto text-gray-600">
        </div>

        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="input w-56 box pr-10 placeholder-theme-13"
              placeholder="Search..."
              v-model="search"
              @keyup.enter="searchUser"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible mt-5">
        <table class="table table-report -mt-10">
          <thead>
            <tr>
              <th class="whitespace-no-wrap">#</th>
              <th class="whitespace-no-wrap">Текст</th>
              <th class="whitespace-no-wrap">Төрөл</th>
              <th class="whitespace-no-wrap">Түвшин</th>
              <th class="whitespace-no-wrap">Level</th>
              <th class="whitespace-no-wrap">Хичээлийн нэр</th>
              <th class="whitespace-no-wrap"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(l, index) in lessons.list"
              :key="index"
              class="intro-x"
            >

            <td class="w-40">
                {{index + 1}}
              </td>
              <td :style="{'max-width': '250px', 'overflow': 'hidden', 'text-overflow': 'ellipsis'}">
                <a href="javascript:;" class="font-medium whitespace-no-wrap">{{
                  l.text
                }}</a>
              </td>
              <td>{{ l.type }}</td>
              <td>{{l.groupName}}</td>
              <td>{{l.lvl}}</td>
              <td>{{l.lessonname}}</td>
              
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center text-theme-4 mr-3" href="javascript:;" @click="editLessonFunc(index)">
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Edit
                  </a>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    @click="deleteCoupon(index)"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Delete
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-no-wrap items-center"
      >
        <ul class="pagination" v-if="totalPage > 1">
          <li v-if="this.page > 1">
            <a class="pagination__link" href="javascript:;" @click="changePage(1)">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>  
          <li v-for="(item, index) in totalPage" :key="index">
            <a class="pagination__link" href="javascript:;" @click="changePage(index + 1)" v-bind:class="{'pagination__link--active': page == index + 1}">{{index + 1}}</a>
          </li>

          <li v-if="page < totalPage">
            <a class="pagination__link" href="javascript:;">
              <ChevronsRightIcon class="w-4 h-4" @click="changePage(totalPage)"/>
            </a>
          </li> 
        </ul>
        <select class="w-20 input box mt-3 sm:mt-0" @change="changeGroup($event)">
          <option>25</option>
          <option>50</option>
        </select>
      </div>
      <!-- END: Pagination -->

      <div id="edit_lesson_modal" ref="edit_lesson_modal" class="modal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
      <div class="modal__content modal__content--xl p-10 text-center">
          <div class="p-5">
              <input type="text" v-model="editLesson.name" maxlength="60" class="input w-full border bg-gray-100 mt-2" placeholder="Засах хичээлийн нэрийг оруулна уу">
              <textarea type="text" v-model="editLesson.text" maxlength="255" class="input w-full border bg-gray-100 mt-2" placeholder="Засах хичээлийн текстийг оруулна уу">
              </textarea>
          </div>
          <div class="px-5 pb-8 text-right">
              <button type="button" data-dismiss="modal" class="button w-24 bg-theme-7 text-white mr-1"> Болих </button>
              <button @click="this.submitEditlesson" type="button" data-dismiss="modal" class="button w-24 bg-theme-1 text-white"> Хадгалах </button> 
          </div>
      </div>
      </div> 
    </div>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        lessons: {
            list: [],
            count: []
        },
        page: 1,
        pageGroup: 25,
        totalPage: 0,
        search: '',
        editLesson: {
          id: '',
          text: '',
          name: ''
        }
      }
    },
    mounted() {
        this.getLessons();
    },
    methods: {
      submitEditlesson() {
        const token = localStorage.getItem('token');
        let rts = this;
        this.$axios({
          method: 'post',
          url: this.$appUrl+'/api/admin/edit-lesson',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: {
            id: this.editLesson.id,
            name: this.editLesson.name,
            text: this.editLesson.text
          }
          }).then(function(data) {
            try {
              rts.lessons.list.forEach(element => {
                if(element.id == rts.editLesson.id) {
                  element.text = rts.editLesson.text; 
                  element.lessonname = rts.editLesson.name;
                }
              });
            } catch (error) {
              console.log(error);
            }
          }).catch(error => {
             console.log(error);
          });
      },
      editLessonFunc(index) {
        const arr = this.lessons.list[index];
        this.editLesson.id = arr.id;
        this.editLesson.name = arr.lessonname;
        this.editLesson.text = arr.text;
        cash('#edit_lesson_modal').modal('show');
      },
      deleteCoupon(index) {
        const arr = this.lessons.list[index];
        const token = localStorage.getItem('token');
        let rts = this;
        this.$msg({
          title: 'Хичээл устгах',
          message:'Та устгахдаа итгэлтэй байна уу?',
          showCancelButton: true,
          confirmButtonText: 'Тийм',
          cancelButtonText: 'Болих',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = 'Түр хүлээнэ үү...';
                this.$axios({
                    method: 'post',
                    url: this.$appUrl+'/api/admin/delete-lesson',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    data: {
                        index: arr.id,
                        level: arr.lvl
                    }
                }).then(function(data) {
                    rts.lessons.list.splice(index, 1);
                    try {
                      rts.lessons.list.forEach(element => {
                        if(element.lvl > arr.lvl) {
                          element.lvl = element.lvl - 1;
                        }
                      });
                    } catch (error) {
                      console.log(error);
                    }
                    instance.confirmButtonLoading = false;
                    done();
                }).catch(error => {
                    instance.confirmButtonLoading = false;
                });
            } else {
              done();
            }
          }
        });
      },
      getLessons() {
        const token = localStorage.getItem('token');
        var rts = this;
        if(token) {
            this.$axios({
                method: 'post',
                url: this.$appUrl+'/api/admin/get-all-lesson',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: {
                    page: this.page,
                    pageGroup: this.pageGroup,
                    search: this.search
                }
            }).then(function(data) {
                rts.lessons.list = data.data.lesson;
                rts.totalPage = data.data.pagination;
            });
        }
      },
      changePage(pg) {
        if(pg != this.page) {
          this.page = pg;
          this.getLessons();
        }
      },
      changeGroup(e) {
        this.page = 1;
        this.pageGroup = e.target.value;
        this.getLessons();
      },
      searchUser() {
        this.page = 1;
        this.getLessons();
      }
    }
  }
</script>