<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Register Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <router-link to="/beginner" class="-intro-x flex items-center pt-5">
            <img
              alt="Midone Tailwind HTML Admin Template"
              class="w-6"
              src="@/assets/images/logo/logo.png"
              style="width: 90px; height: auto;"
            />
          </router-link>
          <div class="my-auto">
            <img
              alt="Midone Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              A few more clicks to <br />
              sign up to your account.
            </div>
            <div class="-intro-x mt-5 text-lg text-white dark:text-gray-500">
              Manage all your e-commerce accounts in one place
            </div>
          </div>
        </div>
        <!-- END: Register Info -->
        <!-- BEGIN: Register Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Бүртгүүлэх
            </h2>
            <div
              class="intro-x mt-2 text-gray-500 dark:text-gray-500 xl:hidden text-center"
            >
              A few more clicks to sign in to your account. Manage all your
              e-commerce accounts in one place
            </div>
            <form @submit.prevent="onSubmit ">
              <div class="intro-x mt-8">
              <input
                type="text"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="Нэр"
                v-model="model.name"
                required
              />
              <input
                type="email"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="И-мэйл хаяг"
                v-model="model.email"
                required
              />
              <input
                type="password"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="Нууц үг"
                v-model="model.password"
                @keyup="SecurePassword"
                required
                pattern=".{6,}"
              />
              <div class="intro-x w-full grid grid-cols-12 gap-4 h-1 mt-3">
                <div class="col-span-3 h-full rounded bg-gray-200" ref="secure1"></div>
                <div class="col-span-3 h-full rounded bg-gray-200" ref="secure2"></div>
                <!-- <div class="col-span-3 h-full rounded bg-grey-200 bg-theme-9"></div> -->
                <div class="col-span-3 h-full rounded bg-gray-200" ref="secure3"></div>
                <div
                  class="col-span-3 h-full rounded bg-gray-200" ref="secure4"
                ></div>
              </div>
              
              <input
                type="password"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="Давтан нууц үг"
                v-model="model.repassword"
                @keyup="ConfirmPassword"
                required
                pattern=".{6,}"
              />

              <div class="intro-x w-full grid grid-cols-12 gap-4 h-1 mt-3">
                <div class="col-span-3 h-full rounded bg-gray-200" ref="repassword"></div>
              </div>
              
            </div>
            <div
              class="intro-x flex items-center text-gray-700 dark:text-gray-600 mt-4 text-xs sm:text-sm"
            >
              <input
                id="remember-me"
                type="checkbox"
                class="input border mr-2"
                required
                v-model="model.agree"
              />
              <a class="text-theme-1 dark:text-theme-10 ml-1" href=""
                >Үйлчилгээний нөхцөл</a
              >
              <label class="cursor-pointer select-none" for="remember-me"
                >-ийг зөвшөөрч байна.</label
              >
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                type="submit"
                class="button button--lg w-full xl:w-40 text-white bg-theme-1 xl:mr-3 align-top"
                v-html="registerBtn"
                :disabled='texts.isDisabled'
              >
                Бүртгүүлэх
              </button>
              <button
                class="button button--lg w-full xl:w-32 text-gray-700 border border-gray-300 dark:border-dark-5 dark:text-gray-300 mt-3 xl:mt-0 align-top"
                @click="Login"
              >
                Нэвтрэх
              </button>
            </div>
            </form>
          </div>
        </div>
        <!-- END: Register Form -->
      </div>
    </div>
  </div>
</template>

<script>
import DarkModeSwitcher from "@/components/DarkModeSwitcher";

export default {
  components: {
    DarkModeSwitcher
  },
  data() {
    return {
      model: {
          name: '',
          email: '',
          password: '',
          repassword: '',
          agree: false,
        },
        texts: {
          registerBtn: 'Бүртгүүлэх',
          isDisabled: false
        }
    }
  },
  mounted() {
    cash("body")
      .removeClass("app")
      .addClass("login");
  }, 
  computed: {
      registerBtn: function() {
        return this.texts.registerBtn;
      }
    },
  methods: {
    SecurePassword() {
      var len = this.model.password.length;
      if(len < 6) 
      {
        this.$refs.secure1.classList.add('bg-red-400');
        this.$refs.secure1.classList.remove('bg-theme-9');
        this.$refs.secure2.classList.remove('bg-theme-9');
        this.$refs.secure3.classList.remove('bg-theme-9');
        this.$refs.secure4.classList.remove('bg-theme-9');
      } else if(len <= 6 || len < 9) {
        this.$refs.secure1.classList.add('bg-theme-9');
        this.$refs.secure2.classList.add('bg-theme-9');
        this.$refs.secure3.classList.remove('bg-theme-9');
        this.$refs.secure4.classList.remove('bg-theme-9');
      } else if(len <= 9 || len < 13) {
        this.$refs.secure1.classList.add('bg-theme-9');
        this.$refs.secure2.classList.add('bg-theme-9');
        this.$refs.secure3.classList.add('bg-theme-9');
        this.$refs.secure4.classList.remove('bg-theme-9');
      } else if(len > 12) {
        this.$refs.secure1.classList.add('bg-theme-9');
        this.$refs.secure2.classList.add('bg-theme-9');
        this.$refs.secure3.classList.add('bg-theme-9');
        this.$refs.secure4.classList.add('bg-theme-9');
      }
      this.ConfirmPassword();
    }, 
    ConfirmPassword() {
      var pass = this.model.password;
      var repass = this.model.repassword;
      if(pass === repass) {
        this.$refs.repassword.classList.add('bg-theme-9');
        this.$refs.repassword.classList.remove('bg-red-400');
      } else {
        this.$refs.repassword.classList.remove('bg-theme-9');
        this.$refs.repassword.classList.add('bg-red-400');
      }
    },
    Login() {
      this.$router.push('/login');
    },
    onSubmit() {
      var rts = this;
      rts.texts.registerBtn = 'Уншиж байна..'
      rts.texts.isDisabled = true;
      this.$axios.get(this.$appUrl+'/api/auth/register', {
          params: {
            name: this.model.name,
            email: this.model.email,
            password: this.model.password
          }
        }).then(function(data){
          rts.texts.registerBtn = 'Бүртгүүлэх'
          rts.texts.isDisabled = false;
          if(data.data.result === 'fail') {
              rts.$toast({
                text: data.data.data,
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: "top",
                position: "center",
                backgroundColor: "#f59314",
                stopOnFocus: true
              }).showToast();

            } else if(data.data.result === 'success') {
              rts.$toast({
                text: data.data.data,
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: "top",
                position: "center",
                backgroundColor: "#216fcf",
                stopOnFocus: true
              }).showToast();
            }
        });
    }
  }
};
</script>
