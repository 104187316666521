<template>
  <LineChart
    :width="width"
    :height="height"
    :chart-data="data"
    :options="options"
  />
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    users: []
  },
  computed: {
    data() {
      return {
        labels: [
          "1 сар",
          "2 сар",
          "3 сар",
          "4 сар",
          "5 сар",
          "6 сар",
          "7 сар",
          "8 сар",
          "9 сар",
          "10 сар",
          "11 сар",
          "12 сар"
        ],
        datasets: [
          {
            label: "Хэрэглэгч",
            data: this.users,
            borderWidth: 2,
            borderColor: "#3160D8",
            backgroundColor: "transparent",
            pointBorderColor: "transparent"
          }
        ]
      };
    },
    options() {
      return {
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontSize: "12",
                fontColor: this.$store.state.main.darkMode
                  ? "#718096"
                  : "#777777"
              },
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontSize: "12",
                fontColor: this.$store.state.main.darkMode
                  ? "#718096"
                  : "#777777",
                callback: function(value) {
                  return  value;
                }
              },
              gridLines: {
                color: this.$store.state.main.darkMode ? "#718096" : "#D8D8D8",
                zeroLineColor: this.$store.state.main.darkMode
                  ? "#718096"
                  : "#D8D8D8",
                borderDash: [2, 2],
                zeroLineBorderDash: [2, 2],
                drawBorder: false
              }
            }
          ]
        }
      };
    }
  }
};
</script>
