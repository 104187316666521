<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Хувийн мэдээлэл</h2>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <!-- BEGIN: Profile Menu -->
      <div
        class="col-span-12 lg:col-span-4 xxl:col-span-3 flex lg:block flex-col-reverse"
      >
        <div class="intro-y box mt-5">
          <div class="relative flex items-center p-5">
            <div class="w-12 h-12 image-fit">
              <img
                class="rounded-full"
                :src="`${user.image}`"
              />
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium text-base">
                {{ user.name }}
              </div>
              <div class="text-gray-600">{{ user.email }}</div>
            </div>
          </div>
          <div class="p-5 border-t border-gray-200 dark:border-dark-5">
            <router-link class="flex items-center mt-2 text-theme-1 dark:text-theme-10 font-medium" to="/update-profile">
              <EditIcon class="w-4 h-4 mr-2" /> Хувийн мэдээлэл
            </router-link>
            <router-link class="flex items-center mt-5" to="change-password">
              <LockIcon class="w-4 h-4 mr-2" /> Нууц үг шинэчлэх
            </router-link>
          </div>
        </div>
      </div>
      <!-- END: Profile Menu -->
      <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
        <!-- BEGIN: Display Information -->
        <div class="intro-y box lg:mt-5">
          <div
            class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Дэлгэрэнгүй мэдээлэл</h2>
          </div>
          <div class="p-5">
            <div class="grid grid-cols-12 gap-5">
              <div class="col-span-12 xl:col-span-4">
                <div
                  class="border border-gray-200 dark:border-dark-5 rounded-md p-5"
                >
                  <div
                    class="w-40 h-40 relative image-fit cursor-pointer zoom-in mx-auto"
                  >
                    <img
                      class="rounded-md"
                      :src="`${user.image}`"
                    />
                  </div>
                  <div class="w-40 mx-auto cursor-pointer relative mt-5">
                    <button
                      type="button"
                      class="button w-full bg-theme-1 text-white"
                    >
                      Зураг солих
                    </button>
                    <input
                      type="file"
                      accept="image/*"
                      class="w-full h-full top-0 left-0 absolute opacity-0"
                      @change="onProfileImageSelected"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-12 xl:col-span-8">
                <div>
                  <label>Нэр</label>
                  <input
                    type="text"
                    class="input w-full border bg-gray-100 cursor-not-allowed mt-2"
                    placeholder="Input text"
                    :value="user.name"
                    disabled
                  />
                </div>
                <div class="mt-3">
                  <label>Цахим хаяг</label>
                  <input
                    type="text"
                    class="input w-full border bg-gray-100 cursor-not-allowed mt-2"
                    placeholder="Input text"
                    :value="user.email"
                    disabled
                  />
                </div>
                <div class="mt-3">
                  <label>Утасны дугаар</label>
                  <input
                    type="text"
                    class="input w-full border mt-2"
                    placeholder="e.g: 0000 0000"
                    v-model="user.phone"
                  />
                </div>
                <div class="mt-3">
                  <label>Хаяг байршил</label>
                  <textarea
                    class="input w-full border mt-2"
                    placeholder="Adress"
                    v-model="user.address"
                  >
                  </textarea
                  >
                </div>
                <button
                  type="button"
                  class="button w-20 bg-theme-1 text-white mt-3"
                  @click="this.updateInfo"
                >
                  Хадгалах
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Display Information -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      select: "1",
      user: {
        name: '',
        email: '',
        phone: '',
        address: '',
        image: ''
      }
    };
  },
  mounted() {
    this.getAdminInfo();
  },
  methods: {
    getAdminInfo() {
      const token = localStorage.getItem('token');
      var rts = this;
      this.$axios({
          method: 'post',
          url: this.$appUrl+'/api/admin/get-admin-info',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(function(data) {
          let i = data.data.data.info;
          rts.user.name = i.name;
          rts.user.email = i.email;
          rts.user.phone = i.phone;
          rts.user.address = i.address;
          rts.user.image = i.image;
        }).catch(error => {
          console.log(error);
        });
    },
    onProfileImageSelected (event) {
      const file = event.target.files[0];
      this.selectedFile = file;
      this.user.image = URL.createObjectURL(file);
      this.updateProfile();
    },
    updateProfile() {
      var rts = this;
      const token = localStorage.getItem('token');
      if(this.selectedFile) {
        const fd = new FormData();
        fd.append('photo', this.selectedFile, this.selectedFile.name);
        this.$axios.post(this.$appUrl+'/api/admin/update-profile', 
        fd,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        )
        .then(function(data) {
          let user = localStorage.getItem('user');
          if(user) {
            console.log(data.data.image);
            user = JSON.parse(localStorage.getItem('user'));
            user.image = data.data.image;
            localStorage.user = JSON.stringify(user);
            // TopMenu.computed.profileImage();
          }
        }).catch(error => {
          console.log(error);
        });
      }
    },
    updateInfo() {
      var rts = this;
      const token = localStorage.getItem('token');
      if(token) {
        this.$axios({
          method: 'post',
          url:this.$appUrl+'/api/admin/update-profile-info', 
          headers: {
            'Authorization': `Bearer ${token}`
            },
          data: {
              phone: this.user.phone,
              address: this.user.address
            }
          }
        )
        .then(function(data) {
          let user = localStorage.getItem('user');
          if(user) {
            user = JSON.parse(localStorage.getItem('user'));
            user.phone = data.data.data;
            localStorage.user = JSON.stringify(user);
          }

          rts.$message({
            message: 'Амжилттай шинэчлэгдлээ',
            type: 'success'
          });

        }).catch(error => {
            console.log(error);
        });
      }
    }
  }
};
</script>
