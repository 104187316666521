<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 19px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #13ce66;
}

input:focus + .slider {
  box-shadow: 0 0 1px #13ce66;
}

input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap items-center mt-2"
      >
        <div class="hidden md:block mx-auto text-gray-600">
        </div>

        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300" align="left">
            <button
              class="button box flex items-center text-gray-700 dark:text-gray-300"
              @click="visibleCoupon"
            >
              <BriefcaseIcon class="hidden sm:block w-4 h-4 mr-2" />
              Шинэ купон код үүсгэх
            </button>
          </div>
        </div>

        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="input w-56 box pr-10 placeholder-theme-13"
              placeholder="Search..."
              v-model="search"
              @keyup.enter="searchUser"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible mt-5">
        <table class="table table-report -mt-10">
          <thead>
            <tr>
              <th class="whitespace-no-wrap">#</th>
              <th class="whitespace-no-wrap">Купон код</th>
              <th class="whitespace-no-wrap">Сунгалтын эрх</th>
              <th class="whitespace-no-wrap">Ашиглагдсан эсэх</th>
              <th class="whitespace-no-wrap">Төлөв</th>
              <th class="whitespace-no-wrap"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(coupon, index) in coupons.list"
              :key="index"
              class="intro-x"
            >
              <td class="w-40">
                {{index + 1}}
              </td>
              <td>
                <a href="javascript:;" class="font-medium whitespace-no-wrap">{{
                  coupon.coupon
                }}</a>
              </td>
              <td>{{ coupon.month }} САР</td>
              <td>
                  <div
                    class="flex items-center"
                    :class="{
                        'text-theme-6': coupon.used == 1,
                        'text-theme-9': coupon.used == 0
                    }"
                    >
                    <CheckSquareIcon class="w-4 h-4 mr-2" />
                    {{ coupon.used == 1 ? "Ашиглагдсан" : "Ашиглагдаагүй" }}
                  </div>
              </td>
              <td>
                    <label class="switch">
                        <input type="checkbox" :checked="coupon.enable" @click="changeStat(index)">
                        <span class="slider round"></span>
                    </label>
              </td>
              
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    @click="deleteCoupon(index)"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Delete
                  </a>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-no-wrap items-center"
      >
        <ul class="pagination" v-if="totalPage > 1">
          <li v-if="this.page > 1">
            <a class="pagination__link" href="javascript:;" @click="changePage(1)">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>  
          <li v-for="(item, index) in totalPage" :key="index">
            <a class="pagination__link" href="javascript:;" @click="changePage(index + 1)" v-bind:class="{'pagination__link--active': page == index + 1}">{{index + 1}}</a>
          </li>

          <li v-if="page < totalPage">
            <a class="pagination__link" href="javascript:;">
              <ChevronsRightIcon class="w-4 h-4" @click="changePage(totalPage)"/>
            </a>
          </li> 
        </ul>
        <select class="w-20 input box mt-3 sm:mt-0" @change="changeGroup($event)">
          <option>25</option>
          <option>50</option>
        </select>
      </div>
      <!-- END: Pagination -->
    </div>
    
    <el-dialog title="Купон код үүсгэх" :visible.sync="CouponDialog.visible">
        <el-form class="pr-10">
            <el-form-item label="Үүсгэх тоо" :label-width="CouponDialog.width">
                <el-input v-model="CouponDialog.count" autocomplete="off" type="number" min="1" max="50"></el-input>
            </el-form-item>
            <el-form-item label="Сунгалтын эрх" :label-width="CouponDialog.width">
                <el-select v-model="CouponDialog.month" placeholder="90 хоног">
                    <el-option label="30 Хоног" value="1"></el-option>
                    <el-option label="90 хоног" value="3"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer pr-10">
            <el-button @click="CouponDialog.visible = false">Цуцлах</el-button>
            <el-button type="primary" @click="createCouponCode">Хадгалах</el-button>
        </span>
    </el-dialog>

  </div>
</template>
<script>
  export default {
    data () {
      return {
        switchVal: 100,
        coupons: {
            list: [],
            count: []
        },
        CouponDialog: {
            count: 1,
            month: '',
            width: '160px',
            visible: false
        },
        page: 1,
        pageGroup: 25,
        totalPage: 0,
        search: ''
      }
    },
    mounted() {
        this.getCoupons();
    },
    methods: {
      createCouponCode() {
          let rts = this;
          if(this.CouponDialog.month != '') {
              if(this.CouponDialog.count > 0 && this.CouponDialog.count != '') {
                const token = localStorage.getItem('token');
                this.$axios({
                    method: 'post',
                    url: this.$appUrl+'/api/admin/create-coupon',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    data: {
                        count: this.CouponDialog.count,
                        level: this.CouponDialog.month
                    }
                }).then(function(data) {
                    rts.$message({
                        message: 'Амжилттай үүсгэгдлээ',
                        type: 'success'
                    });
                    rts.CouponDialog.visible = false;
                    rts.getCoupons();
                });
              } else {
                this.$message({
                    message: 'Мэдээлэл дутуу байна',
                    type: 'error'
                });
              }
          } else {
            this.$message({
                message: 'Мэдээлэл дутуу байна',
                type: 'error'
            });
          }
      },
      visibleCoupon() {
          this.CouponDialog.visible = true;
      },
      changeStat(index) {
          const arr = this.coupons.list[index];
          let status = 0;
          const token = localStorage.getItem('token');
          if(!arr.enable) {
              status = 1;
          }
          this.$axios({
            method: 'post',
            url: this.$appUrl+'/api/admin/change-coupon-status',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: {
                status,
                couponCode: arr.coupon,
            }
        }).then(function(data) {
            arr.enable = status;
        });
          
      },
      deleteCoupon(index) {
        const arr = this.coupons.list[index];
        const token = localStorage.getItem('token');
        let rts = this;
        this.$msg({
          title: 'Купон код устгах',
          message:'Та устгахдаа итгэлтэй байна уу?',
          showCancelButton: true,
          confirmButtonText: 'Тийм',
          cancelButtonText: 'Болих',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = 'Түр хүлээнэ үү...';
                this.$axios({
                    method: 'post',
                    url: this.$appUrl+'/api/admin/delete-coupon',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    data: {
                        couponCode: arr.coupon,
                    }
                }).then(function(data) {
                    rts.coupons.list.splice(index, 1);
                    instance.confirmButtonLoading = false;
                    done();
                }).catch(error => {
                    console.log(error);
                    instance.confirmButtonLoading = false;
                });
            } else {
              done();
            }
          }
        });
      },
      getCoupons() {
        const token = localStorage.getItem('token');
        var rts = this;
        if(token) {
            this.$axios({
                method: 'post',
                url: this.$appUrl+'/api/admin/all-coupons',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: {
                    page: this.page,
                    pageGroup: this.pageGroup,
                    search: this.search
                }
            }).then(function(data) {
                rts.coupons.list = data.data.coupons;
                rts.totalPage = data.data.pagination;
            });
        }
      },
      checkRank(id) {
          let count = 0;
          let stage = this.users.count.some(function(field) {
              return field.id == id;
          });

          if(stage) {
              this.users.count.forEach(element => {
                  if(element.id == id) {
                      count = element.count;
                  }
              });
          }
          return count;
      },
      changePage(pg) {
        if(pg != this.page) {
          this.page = pg;
          this.getCoupons();
        }
      },
      changeGroup(e) {
        this.page = 1;
        this.pageGroup = e.target.value;
        this.getCoupons();
      },
      searchUser() {
        this.page = 1;
        this.getCoupons();
      }
    }
  }
</script>