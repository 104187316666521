import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from 'axios';
import store from "./store";
import "./global-components";
import "./utils";
import "./libs";
import Toastify from "toastify-js";
import {Message, MessageBox} from 'element-ui';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
import refreshToken from "@/components/refreshToken";

// Midone Theme
import "./assets/sass/app.scss";
import VueSocketio from 'vue-socket.io';


Vue.prototype.$newSocket = VueSocketio;
var AppURL = 'https://api.hurdanhuruu.mn';
// var AppURL = 'http://localhost:8002';
Vue.config.productionTip = false;
Vue.prototype.$appUrl = AppURL;
Vue.prototype.$axios = axios;
Vue.prototype.$toast = Toastify;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$msg = MessageBox;
Vue.prototype.$checkAuth = refreshToken;
Vue.use(ElementUI, { locale })
Vue.use(new VueSocketio({
  debug: false, 
  connection: 'https://api.hurdanhuruu.mn',
  // connection:'http://localhost:8002',
  options: { query: { token: localStorage.getItem('token') } }  
}));

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
